import React, { useState, useEffect } from "react";
import { Modal, Button, Table, OverlayTrigger, Popover } from "react-bootstrap";
import { FaInfoCircle } from "react-icons/fa";
import { db } from "../../firebaseConfig"; // Import your firebase config
import { collection, query, where, getDocs, doc, getDoc } from "firebase/firestore";

const ViewPopUp = ({ show, handleClose, ideaId }) => {
  const [tableData, setTableData] = useState([]); // Store user and status data
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch user status data when ideaId changes
  useEffect(() => {
    if (!ideaId) return;

    const fetchUserStatusData = async () => {
      try {
        const statusRef = collection(db, "user_idea_statuses");
        const q = query(statusRef, where("ideaId", "==", ideaId));
        const querySnapshot = await getDocs(q);
        const userStatusData = [];

        for (const docSnapshot of querySnapshot.docs) {
          const data = docSnapshot.data();
          const userRef = doc(db, "users", data.userId); // Fetch user data by userId
          const userDoc = await getDoc(userRef);

          if (userDoc.exists()) {
            userStatusData.push({
              userName: userDoc.data().firstName,
              status: data.status,
              pitch: data.pitch || "No pitch provided", // Add pitch field
            });
          }
        }

        setTableData(userStatusData); // Set fetched data to state
      } catch (error) {
        setError("Error fetching data");
        console.error("Error fetching user status data: ", error);
      } finally {
        setLoading(false); // Stop loading after data is fetched
      }
    };


    fetchUserStatusData();
  }, [ideaId]);

  const getStatusColor = (status) => {
    switch (status) {
      case "Won Idea":
        return "success";
      case "Rejected":
        return "danger";
      case "Pending":
        return "warning";
      default:
        return "secondary";
    }
  };

  return (
    <Modal show={show} onHide={handleClose} centered size="lg">
      {/* CSS Styling */}
      <style>
        {`
          .popup-title {
            font-weight: bold;
            font-size: 1.5rem;
            margin-bottom: 10px;
          }
          .popup-description {
            font-size: 1rem;
            color: #555;
            margin-bottom: 20px;
            margin-left:20px;
            margin_right:20px;
          }
          .table-header {
            background-color: #f8f9fa;
            font-weight: bold;
          }
          .user-details {
            display: flex;
            align-items: center;
            gap: 8px;
          }
          .details-icon {
            font-size: 18px;
            cursor: pointer;
            color: #d6a63e;
          }
          .popover-body {
            border-radius: 10px;
            box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
            padding: 15px;
            background-color: #ffffff;
          }
          .popover-textarea {
            width: 100%;
            resize: none;
            padding: 10px;
            border: 1px solid #ddd;
            border-radius: 8px;
            font-size: 0.9rem;
            color: #333;
          }
          .popover-header {
            font-size: 1.2rem;
            font-weight: bold;
            margin-bottom: 10px;
          }
        `}
      </style>

      <Modal.Header closeButton>
        <Modal.Title className="popup-title">Idea Name</Modal.Title>
      </Modal.Header>
      <p className="popup-description">
        A marketplace for eco-friendly innovations that support greener cities. EcoTech connects
        innovators with city planners to promote sustainable growth and cleaner urban environments.
      </p>
      <Modal.Body>
        {loading ? (
          <p>Loading...</p>
        ) : error ? (
          <p className="text-danger">{error}</p>
        ) : (
          <Table bordered responsive>
            <thead>
              <tr className="table-header">
                <th>User Name</th>
                <th>Idea Status</th>
              </tr>
            </thead>
            <tbody>
              {tableData.map((data, index) => (
                <tr key={index}>
                  <td>
                    <div className="user-details">
                      {data.userName}
                      <OverlayTrigger
                        trigger="click"
                        placement="bottom"
                        rootClose
                        overlay={
                          <Popover id={`popover-${index}`} className="custom-popover">
                            <Popover.Body>
                              <div className="popover-header">Pitch Details</div>
                              <textarea
                                className="popover-textarea"
                                placeholder={data.pitch}
                                rows="4"
                                onClick={(e) => e.stopPropagation()} // Prevent popover from closing when interacting inside
                              ></textarea>
                            </Popover.Body>
                          </Popover>
                        }
                      >
                        <span>
                          <FaInfoCircle className="details-icon" />
                        </span>
                      </OverlayTrigger>
                    </div>
                  </td>

                  <td>
                    <Button variant={getStatusColor(data.status)}>{data.status}</Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ViewPopUp;
