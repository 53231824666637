import React, { useState, useEffect } from "react";
import { FaTimes } from "react-icons/fa";
import "bootstrap/dist/css/bootstrap.min.css";

const Notification = ({ text, onClose }) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(false);
      onClose && onClose(); // Notify the parent to handle state
    }, 2000); // 2 seconds

    return () => clearTimeout(timer); // Cleanup timer
  }, [onClose]);

  const handleClose = () => {
    setIsVisible(false);
    onClose && onClose(); // Notify the parent to handle state
  };

  if (!isVisible) return null;

  return (
    <div style={styles.container}>
      <div style={styles.text}>{text}</div>
      <FaTimes style={styles.closeIcon} onClick={handleClose} />
    </div>
  );
};

const styles = {
  container: {
    position: "fixed",
    bottom: "20px",
    right: "20px",
    width: "260px",
    height: "80px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    background: "linear-gradient(135deg, #000000, #1C1C1C)",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
    borderRadius: "10px",
    padding: "10px",
    color: "white",
    fontSize: "14px",
    fontWeight: "bold",
    zIndex: 1000,
    animation: "fadeIn 0.5s",
  },
  text: {
    flexGrow: 1,
    marginRight: "10px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  closeIcon: {
    cursor: "pointer",
    color: "white",
    fontSize: "16px",
  },
};

export default Notification;
