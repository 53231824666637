import React, { useState, useEffect } from "react";
import { Table, Button, Form, Pagination, Modal, Spinner } from "react-bootstrap";
import { FaEye, FaEdit, FaTrashAlt, FaPaperPlane } from "react-icons/fa";
import ViewPopUp from '../../components/pop_up/view';
import SendToPopUp from '../../components/pop_up/sendTo';
import { fetchIdea } from "../../controllers/admin_pannel/fetchIdeaSubmissionController";
import { doc, deleteDoc } from "firebase/firestore";
import { db } from "../../firebaseConfig";
import { useNavigate } from 'react-router-dom';
import { fetchCategories } from "../../controllers/categoriesController";
import { fetchNiches } from "../../controllers/nicheController";
import Notification from "../../components/pop_up/notification";

const AdminPannelIdeaManagement = () => {
  const [formData, setFormData] = useState({
    category: "",
    niche: "",
  });
  const handleClearFilters = () => {
    setFormData({
      category: "",
      niche: "",
    });
    setFilteredNiches([]); // Clear filtered niches as well
    setFilteredIdeas(ideas); // Reset the filtered ideas to show all ideas
  };

  const [ideas, setIdeas] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [categories, setCategories] = useState([]);
  const [niches, setNiches] = useState([]);
  const [filteredNiches, setFilteredNiches] = useState([]);
  const [filteredIdeas, setFilteredIdeas] = useState([]); 
  const [notification, setNotification] = useState(null);

  const applyFilters = () => {
    const { category, niche } = formData;

    // Start with the full ideas list
    let filtered = [...ideas];

    // Apply category filter
    if (category) {
      filtered = filtered.filter((idea) => idea.category === category);
    }

    // Apply niche filter
    if (niche) {
      filtered = filtered.filter((idea) => idea.niche === niche);
    }

    // Update the filteredIdeas state
    setFilteredIdeas(filtered);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const categoryData = await fetchCategories();
        setCategories(categoryData);

        const nicheData = await fetchNiches();
        setNiches(nicheData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    // Filter niches based on the selected category
    if (formData.category) {
      const filtered = niches.filter(
        (niche) => niche.categoryId === formData.category
      );
      setFilteredNiches(filtered);

      // If there are no valid niches after category change, clear 'niche' value
      if (filtered.length === 0) {
        setFormData((prev) => ({ ...prev, niche: "" }));
      }
    } else {
      setFilteredNiches([]);
    }
  }, [formData.category, niches]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const [showViewPopup, setShowViewPopup] = useState(false);
  const [showSendToPopup, setShowSendToPopup] = useState(false);
  const [currentPage, setCurrentPage] = useState(1); // Pagination state
  const itemsPerPage = 10;

  const handleCloseViewPopup = () => setShowViewPopup(false);

  const [selectedIdeaId, setSelectedIdeaId] = useState(null); // Track selected idea ID

  const handleShowSendToPopup = (ideaId, ideaTitle) => {
    setSelectedIdeaId(ideaId, ideaTitle); // Set the selected idea ID
    setShowSendToPopup(true);
  };

  const handleCloseSendToPopup = () => setShowSendToPopup(false);

  const [showFilters, setShowFilters] = useState(false);
  const handleShowFilters = () => setShowFilters(true);
  const handleCloseFilters = () => setShowFilters(false);

  const handleDeleteIdea = async (id) => {
    // Confirm delete action
    if (window.confirm("Are you sure you want to delete this idea?")) {
      try {
        // Delete from Firestore
        const ideaRef = doc(db, "ideas", id); // Assuming 'ideas' is your collection name
        await deleteDoc(ideaRef);

        // Update state to remove the idea locally
        setIdeas((prevIdeas) => prevIdeas.filter((idea) => idea.id !== id));
        setNotification("Idea deleted successfully!");
      } catch (error) {
        console.error("Error deleting idea: ", error);
        setNotification(error);
      }
    }
  };

  const navigate = useNavigate(); // Initialize navigate

  const handleEditClick = (idea) => {
    navigate(`/admin/editIdeaForm`, { state: { idea } });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchIdea();
        setIdeas(data);
        setFilteredIdeas(data);  // Set filteredIdeas as the full list initially
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // Calculate pagination data
  const totalItems = filteredIdeas.length; // Use filteredIdeas for pagination
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredIdeas.slice(indexOfFirstItem, indexOfLastItem); // Slice filteredIdeas

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  return (
    <>
      <style type="text/css">
        {`
          /* No changes to your existing styles */
          .idea-management-container {
            max-width: 1200px;
            margin: 0 auto;
            padding: 20px;
            background: #fff;
          }
          @media (min-width: 992px) {
            .idea-management-container {
              padding-left: 242px;
            }
          }
          @media (max-width: 991px) {
            .idea-management-container {
              padding-left: 8px;
              padding-right: 8px;
            }
          }
          .page-title {
            font-size: 40px;
            font-weight: bold;
            margin-bottom: 30px;
            color: #000;
          }
          .header-actions {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-bottom: 20px;
            gap: 4px;
          }
          .filter-button {
            background: transparent;
            border: 1px solid #d6a63e;
            color: #d6a63e;
            border-radius: 5px;
            padding: 6px 12px;
            font-weight: 500;
          }
          .filter-button:hover {
            background: #d6a63e;
            color: #fff;
            border: none;
          }
          .add-idea-button {
            background-color: #d6a63e;
            color: #fff;
            border: none;
            max-width: 160px;
            border-radius: 4px;
            padding: 7px 12px;
          }
          .add-idea-button:hover {
            background-color: #c59532;
          }
          .clear-idea-button {
            color: black;
            background-color: white;
            border: 1px solid #d6a63e;
            border-radius: 30px;
            padding: 8px 18px;
            font-size: 16px;
            font-weight: bold;
          }
          .clear-idea-button:hover {
            background-color: #c59532;
            border: none;
          }
          .idea-table {
            margin-top: 20px;
          }
          .idea-table th {
            font-weight: 600;
            color: #000;
          }
          .idea-table td {
            vertical-align: middle;
          }
          .idea-image {
            width: 50px;
            height: 50px;
            border-radius: 8px;
            object-fit: cover;
          }
          .idea-title {
            font-weight: bold;
            margin-bottom: 5px;
            color: #000;
          }
          .idea-description {
            font-size: 14px;
            color: #555;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .icon-button {
            background: none;
            border: none;
            font-size: 18px;
            margin-right: 3px;
            cursor: pointer;
          }
          .icon-eye {
            color: #d6a63e;
          }
          .icon-edit,
          .icon-trash {
            color: #000;
          }
          .icon-send {
            color: #d6a63e;
          }
          .icon-button:hover {
            opacity: 0.8;
          }
          .pagination-container .pagination {
            color: #c59532;
          }
          .pagination-container .page-link {
            color: #c59532;
            border: 1px solid #c59532;
            background-color: transparent;
            border-radius: 5px;
          }
          .pagination-container .page-link:hover {
            background-color: #c59532 !important;
            color: #fff !important;
          }
          .pagination-container .active .page-link {
            background-color: #c59532;
            color: #fff;
            border-color: #c59532;
          }
        `}
      </style>

      <div className="idea-management-container">
        <h1 className="page-title">Idea Management</h1>
        <div className="header-actions">
          <Button className="filter-button" onClick={handleShowFilters}>
            Filters
          </Button>
          <Button href="/admin/ideaSubmission" className="add-idea-button">
            + Add Idea
          </Button>
        </div>

        {loading ? (
          <div className="loading-text">
            <Spinner animation="border" /> Loading...
          </div>
        ) : error ? (
          <div className="text-center text-danger my-4">
            <p>{error}</p>
          </div>
        ) : (
          <Table bordered hover className="idea-table">
            <thead>
              <tr>
                <th>
                  <Form.Check type="checkbox" />
                </th>
                <th>Title</th>
                <th>Description</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((idea) => (
                <tr key={idea.id}>
                  <td>
                    <Form.Check type="checkbox" />
                  </td>
                  <td>
                    <div className="d-flex align-items-center">
                      <img
                        src={idea.ideaImage || "image"}
                        alt="Idea Thumbnail"
                        className="idea-image"
                      />
                      <div className="ms-3">
                        <p className="idea-title">{idea.title}</p>
                      </div>
                    </div>
                  </td>
                  <td>
                    <p className="idea-description">{idea.description}</p>
                  </td>
                  <td>
                    <FaEye
                      className="icon-button icon-eye"
                      onClick={() => {
                        setSelectedIdeaId(idea.id); // Set the ideaId to state
                        setShowViewPopup(true);    // Show the popup
                      }}
                    />
                    <ViewPopUp
                      show={showViewPopup}
                      handleClose={handleCloseViewPopup}
                      ideaId={selectedIdeaId} // Pass the selected ideaId as a prop
                    />
                  </td>
                  <td>
                    <div className="d-flex">
                      <button onClick={() => handleEditClick(idea)} className="icon-button">
                        <FaEdit className="icon-edit" />
                      </button>
                      <button
                        className="icon-button"
                        onClick={() => handleDeleteIdea(idea.id)}
                      >
                        <FaTrashAlt className="icon-trash" />
                      </button>
                      {idea.status !== "Won Idea" && (
                        <button
                          className="icon-button"
                          onClick={() => handleShowSendToPopup(idea.id, idea.title)} // Pass idea ID
                        >
                          <FaPaperPlane className="icon-send" />
                        </button>
                      )}
                      <SendToPopUp
                        show={showSendToPopup}
                        handleClose={handleCloseSendToPopup}
                        ideaId={selectedIdeaId} // Pass ideaId as prop
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}

        <div className="pagination-container">
          <Pagination>
            <Pagination.Prev
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              Previous
            </Pagination.Prev>
            {[...Array(totalPages)].map((_, index) => (
              <Pagination.Item
                className="ms-1 me-1"
                key={index + 1}
                active={index + 1 === currentPage}
                onClick={() => handlePageChange(index + 1)}
              >
                {index + 1}
              </Pagination.Item>
            ))}
            <Pagination.Next
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              Next
            </Pagination.Next>
          </Pagination>
        </div>
      </div>

      {/* Filters Modal */}
      <Modal show={showFilters} onHide={handleCloseFilters} centered>
        <Modal.Body>
          <div className="filters-popup">
            <h4 className="text-center mb-4">Filters</h4>
            <Form>
              <Form.Group className="mb-3">
                <Form.Label className="form-label">Category</Form.Label>
                <Form.Select
                  name="category"
                  value={formData.category}
                  onChange={handleChange}
                >
                  <option value="" disabled>
                    Select a category
                  </option>
                  {categories.map((category) => (
                    <option key={category.id} value={category.id}>
                      {category.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label className="form-label">Niche</Form.Label>
                <Form.Select
                  name="niche"
                  value={formData.niche}
                  onChange={handleChange}
                >
                  <option value="" disabled>
                    Select a niche
                  </option>
                  {filteredNiches.map((niche) => (
                    <option key={niche.id} value={niche.niche}>
                      {niche.niche}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <div className="d-flex justify-content-between">
                <Button
                  className="add-idea-button"
                  onClick={applyFilters} // Ensure filters are applied
                >
                  Apply Filter
                </Button>
                <Button
                  className="clear-idea-button"
                  onClick={handleClearFilters} // Clear the filters
                >
                  Clear Filter
                </Button>
              </div>
            </Form>
          </div>
        </Modal.Body>
      </Modal>
      {notification && (
        <Notification
          text={notification}
          onClose={() => setNotification(null)}
        />
      )}
    </>
  );
};

export default AdminPannelIdeaManagement;
