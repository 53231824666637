import React from 'react';
import { Form,  Row, Col, Container } from 'react-bootstrap';
import { useLocation } from "react-router-dom";

function AdminPannelUserProfilePage() {
  const location = useLocation();
  const user = location.state;

  return (
    <Container className="d-flex justify-content-center align-items-center min-vh-100">
      <div className="create-account-container">
        <div className="d-flex align-items-center mb-4">
          <h2 className="profile-header">Profile</h2>
        </div>

        <Form className="account-form">
          <Row>
            <Col md={6}>
              <Form.Group controlId="firstName">
                <Form.Label className="form-label">First Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your first name"
                  value={user?.firstName || ''}
                  readOnly
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="lastName">
                <Form.Label className="form-label">Last Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your last name"
                  value={user?.lastName || ''}
                  readOnly
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group controlId="email">
                <Form.Label className="form-label">Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter your email"
                  value={user?.email || ''}
                  readOnly
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="password">
                <Form.Label className="form-label">Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Enter your password"
                  value={user?.password || ''}
                  readOnly
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group controlId="address">
                <Form.Label className="form-label">Address</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your address"
                  value={user?.address || ''}
                  readOnly
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="country">
                <Form.Label className="form-label">Country</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your country"
                  value={user?.country || ''}
                  readOnly
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group controlId="nationality">
                <Form.Label className="form-label">Nationality</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your nationality"
                  value={user?.nationality || ''}
                  readOnly
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="investmentRange">
                <Form.Label className="form-label">Investment Range</Form.Label>
                <Form.Control as="select" value={user?.investmentRange || ''} disabled>
                  <option>999 - 3000 $</option>
                  <option>3001 - 5000 $</option>
                  <option>5001 - 10000 $</option>
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group controlId="category">
                <Form.Label className="form-label">Category</Form.Label>
                <Form.Control as="select" value={user?.category || ''} disabled>
                  <option>{user.category}</option>
                </Form.Control>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="niche">
                <Form.Label className="form-label">Niche</Form.Label>
                <Form.Control as="select" value={user?.niche || ''} disabled>
                <option>{user.niche}</option>
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
        </Form>

        <style jsx>{`
          .create-account-container {
            width: 100%;
            max-width: 800px;
            padding: 2rem;
            background-color: #ffffff;
            border-radius: 15px;
            box-shadow: 0 6px 18px rgba(0, 0, 0, 0.1);
            margin-bottom: 60px;
            margin-left: 240px;
          }

          @media (max-width: 992px) {
            .create-account-container {
              margin-left: 0;
              padding-top: 20px;
            }
          }

          .account-form {
            margin-top: 1rem;
          }

          .form-label {
            display: block;
            font-weight: 600;
            margin-bottom: 0.3rem;
            color: #333;
            text-align: left;
          }
        `}</style>
      </div>
    </Container>
  );
}

export default AdminPannelUserProfilePage;
