import React, { useEffect, useState } from "react";
import { Modal, Table, Button, Form } from "react-bootstrap";
import { fetchUsers } from "../../controllers/admin_pannel/userManagmentController";
import { updateUserIdeas } from "../../controllers/admin_pannel/pop_ups/sendToUserIdeaController"; // Assuming a controller for database updates
import Notification from "./notification";

const SendToPopUp = ({ show, handleClose, ideaId, ideaTitle }) => {
  const [userList, setUserList] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [notification, setNotification] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false); 

  // Fetch users dynamically
  useEffect(() => {
    const getUsers = async () => {
      try {
        const users = await fetchUsers();
        const filteredUsers = users.filter(
          (user) => user.status === "Awaiting for Idea"
        );
        setUserList(filteredUsers);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching users:", error);
        setIsLoading(false);
      }
    };

    if (show) {
      setIsLoading(true);
      getUsers();
    }
  }, [show]);

  // Handle user selection
  const handleUserSelection = (userId) => {
    setSelectedUsers((prev) =>
      prev.includes(userId)
        ? prev.filter((id) => id !== userId) // Deselect user
        : [...prev, userId] // Select user
    );
  };

  // Handle send action
  const handleSend = async () => {
    setIsProcessing(true);
    if (selectedUsers.length === 0) {
      setNotification("Please select at least one user."); 
      return;
    }

    try {
      await Promise.all(
        selectedUsers.map((userId) => updateUserIdeas(userId, ideaId))
      );
      setNotification("Idea sent successfully to selected users!"); 
      setSelectedUsers([]); // Clear selections
      setIsProcessing(false);
      handleClose(); // Close the popup
    } catch (error) {
      console.error("Error sending idea:", error);
      setNotification("Failed to send the idea. Please try again."); 
    }
  };

  return (
    <>
      <style>
        {`
          .popup-title {
            font-weight: bold;
            font-size: 1.5rem;
            margin-bottom: 10px;
          }
          .popup-description {
            font-size: 1rem;
            color: #555;
            margin-bottom: 20px;
          }
          .custom-table {
            background-color: #f7f9fc;
            border-radius: 10px;
            overflow: hidden;
          }
          .custom-table th {
            background-color: #eaf1f8;
            font-weight: bold;
            text-align: left;
            padding: 10px;
          }
          .custom-table td {
            padding: 10px;
          }
          .footer-send-btn {
            background-color: #ffcc47;
            border: none;
            font-weight: bold;
            color: #000;
            padding: 10px 20px;
            border-radius: 8px;
            font-size: 1rem;
          }
          .footer-send-btn:hover,
          .footer-send-btn:focus,
          .footer-send-btn:active {
            background-color: #ffcc47 !important; /* Keeps the same color on hover */
            color: #000 !important; /* Keeps the same text color */
            box-shadow: none !important; /* Removes any shadow effects */
          }
          @media (max-width: 768px) {
            .popup-title {
              font-size: 1.2rem;
            }
            .popup-description {
              font-size: 0.9rem;
            }
            .footer-send-btn {
              font-size: 0.9rem;
              padding: 8px 16px;
            }
          }
        `}
      </style>

      <Modal show={show} onHide={handleClose} centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title className="popup-title">Send Idea</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p className="popup-description">
            Select users to send the idea titled <strong>{ideaTitle}</strong>.
          </p>

          {isLoading ? (
            <p>Loading users...</p>
          ) : (
            <Table className="custom-table" responsive>
              <thead>
                <tr>
                  <th>
                    <Form.Check
                      type="checkbox"
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedUsers(userList.map((user) => user.id));
                        } else {
                          setSelectedUsers([]);
                        }
                      }}
                      checked={
                        userList.length > 0 &&
                        selectedUsers.length === userList.length
                      }
                    />
                  </th>
                  <th>User Name</th>
                </tr>
              </thead>
              <tbody>
                {userList.map((user) => (
                  <tr key={user.id}>
                    <td>
                      <Form.Check
                        type="checkbox"
                        onChange={() => handleUserSelection(user.id)}
                        checked={selectedUsers.includes(user.id)}
                      />
                    </td>
                    <td>{user.firstName + " " + user.lastName}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button
            className="footer-send-btn"
            onClick={handleSend}
            disabled={selectedUsers.length === 0}
          >
          {isProcessing ? "Processing..." : "Send"}
          </Button>
        </Modal.Footer>
      </Modal>
      {notification && (
        <Notification
          text={notification}
          onClose={() => setNotification(null)}
        />
      )}
    </>
  );
};

export default SendToPopUp;
