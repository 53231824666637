import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import HeroAboutUs from "../components/about_us/hero";
import Questions from "../components/about_us/questions";
import WhyChooseTL from "../components/about_us/whyChooseTl";
import GetInTouch from "../components/about_us/getInTouch";
import AboutMe from "../components/about_us/aboutMe";
import Review from "../components/about_us/review";

function AboutUs() {
  const { hash } = useLocation(); 

  useEffect(() => {
    if (hash) {
      const targetElement = document.querySelector(hash);
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: "smooth" }); 
      }
    }
  }, [hash]); 

  return (
    <div>
     <HeroAboutUs/>
     <div id="howWorks">
     <Questions/>
      </div>
     <WhyChooseTL/>
     <AboutMe/>
     <br/>
     <br/>
     <br/>
     <Review/>
     <GetInTouch/>
    </div>
  );
}


export default AboutUs;
