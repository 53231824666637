import React, { useState } from "react";
import { Container, Form, Button, Card, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../../firebaseConfig"; // Ensure this is your Firebase setup file
import UserService from "../../controllers/currentUserId"; // Import the user service for user ID
import AccountCreationPopUp from "../../components/pop_up/accountCreation";
import { useNavigate } from "react-router-dom";

const AccountCreationPayment = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [formData, setFormData] = useState({
    paymentMethod: "",
    selectedDate: "", // New field for the calendar date
  });
  const [errors, setErrors] = useState({}); // State for form validation errors
  const [isProcessing, setIsProcessing] = useState(false); // Button processing state
  const navigate = useNavigate();

  const validateForm = () => {
    const newErrors = {};

    // Validate payment method
    if (!formData.paymentMethod) {
      newErrors.paymentMethod = "Please select a payment method.";
    }

    // Validate card holder name
    if (!formData.cardHolderName?.trim()) {
      newErrors.cardHolderName = "Card holder name is required.";
    }

    // Validate card number: must be 14 digits
    if (!formData.cardNumber?.trim()) {
      newErrors.cardNumber = "Card number is required.";
    } else if (!/^\d{14}$/.test(formData.cardNumber)) {
      newErrors.cardNumber = "Card number must be 14 digits.";
    }

    // Validate selected date
    if (!formData.selectedDate) {
      newErrors.selectedDate = "Please select a date.";
    }

    setErrors(newErrors);

    // Return true if there are no errors
    return Object.keys(newErrors).length === 0;
  };


  const handleShow = async (e) => {
    e.preventDefault(); // Prevent default form submission
  
    if (!validateForm()) return; // If validation fails, stop execution
  
    setIsProcessing(true); // Set processing state
    try {
      // Simulate payment confirmation (replace this logic with actual API/SDK calls)
      const paymentConfirmed = true; // Example: replace with real payment confirmation
  
      const userId = UserService.getCurrentUserId();
  
      // Prepare transaction data
      const { paymentMethod } = formData; // Destructure non-sensitive data
      const transactionData = {
        userId,
        paymentMethod,
        amount: 100,
        type: "Account Creation",
        status: paymentConfirmed ? "success" : "error", // Dynamically set status
        createdAt: new Date().toISOString(), // Timestamp
      };
  
      // Store in Firestore
      await addDoc(collection(db, "transactions"), transactionData);
  
      if (paymentConfirmed) {
        setShowPopup(true); // Show popup on success
      } else {
        console.error("Payment failed.");
      }
    } catch (error) {
      console.error("Error storing transaction: ", error);
    } finally {
      setIsProcessing(false); // Reset processing state
    }
  };
  

  const handleClose = () => {
    setShowPopup(false);
    navigate("/"); // Navigate to home route after closing popup
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleDateChange = (e) => {
    setFormData({ ...formData, selectedDate: e.target.value });
  };

  return (
    <Container className="py-5">
      <style>
        {`
          .container-wrapper {
            max-width: 600px;
            margin: 0 auto;
          }

          .payment-header {
            font-size: 2rem;
            font-weight: bold;
            color: #333;
            text-align: center;
            margin-bottom: 30px;
          }

          .top-row {
            background: #f8f3e3;
            padding: 15px 20px;
            border-radius: 10px;
            margin-bottom: 25px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 1.2rem;
            font-weight: 600;
            color: #444;
            box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
          }

          .fee-amount {
            color: #c69e59;
            font-size: 1.5rem;
            font-weight: bold;
          }

          .payment-card {
            background: #fff;
            padding: 30px;
            border-radius: 12px;
            box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
            margin: 0 auto;
          }

          .payment-option {
            display: flex;
            align-items: center;
            margin-bottom: 20px;
          }

          .payment-option img {
            width: 40px;
            height: 40px;
            border-radius: 6px;
            margin-right: 15px;
          }

          .payment-option label {
            font-size: 1.1rem;
            font-weight: 500;
            color: #333;
            margin-bottom: 0;
            flex-grow: 1;
            text-align: start;
          }

          .payment-input {
            padding: 10px 15px;
            border-radius: 8px;
            border: 1px solid #ddd;
            width: 60%;
          }

          .error-text {
            color: red;
            font-size: 0.9rem;
            margin-top: 5px;
            text-align: left; /* Ensure alignment */
          }

          .submit-btn {
            background-color: #c69e59;
            color: white;
            border: none;
            border-radius: 30px;
            padding: 10px;
            font-size: 1rem;
            font-weight: bold;
            width: 220px;
            display: block;
            margin: 30px auto 0 auto;
            box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
          }

          .submit-btn:hover {
            background-color: #b08445;
          }

          .processing {
            cursor: not-allowed;
            opacity: 0.8;
          }
        `}
      </style>

      <div className="container-wrapper">
        <h1 className="payment-header">Payment Method</h1>
        <Row className="top-row">
          <Col className="text-start">
            <span className="fee-amount">100 $</span>
          </Col>
          <Col className="text-end">Account Creation Fee</Col>
        </Row>
        <Card className="payment-card">
          <Form>
            <div className="payment-option">
              <img src="/images/slider.png" alt="Credit Card" />
              <Form.Check
                type="radio"
                name="paymentMethod"
                id="creditCard"
                className="me-2"
                value="Credit Card"
                onChange={handleInputChange}
              />
              <label htmlFor="creditCard">Credit Card</label>
              <Form.Control
                type="text"
                name="cardHolderName"
                placeholder="Card Holder Name"
                className="payment-input"
                onChange={handleInputChange}
              />
              {errors.cardHolderName && <div className="error-text">{errors.cardHolderName}</div>}
            </div>

            <div className="payment-option">
              <img src="/images/slider.png" alt="PayPal" />
              <Form.Check
                type="radio"
                name="paymentMethod"
                id="paypal"
                className="me-2"
                value="PayPal"
                onChange={handleInputChange}
              />
              <label htmlFor="paypal">PayPal</label>
              <Form.Control
                type="text"
                name="cardNumber"
                placeholder="Card Number"
                className="payment-input"
                onChange={handleInputChange}
              />
              {errors.cardNumber && <div className="error-text">{errors.cardNumber}</div>}
            </div>

            <div className="payment-option">
              <img src="/images/slider.png" alt="Google Pay" />
              <Form.Check
                type="radio"
                name="paymentMethod"
                id="googlePay"
                className="me-2"
                value="Google Pay"
                onChange={handleInputChange}
              />
              <label htmlFor="googlePay">Google Pay</label>
              <Form.Control
                type="date"
                name="selectedDate"
                className="payment-input"
                onChange={handleDateChange}
              />
              {errors.selectedDate && <div className="error-text">{errors.selectedDate}</div>}
            </div>

            <Button
              type="submit"
              onClick={handleShow}
              className={`submit-btn ${isProcessing ? "processing" : ""}`}
              disabled={isProcessing}
            >
              {isProcessing ? "Processing..." : "Confirm Payment"}
            </Button>
            <AccountCreationPopUp show={showPopup} handleClose={handleClose} />
          </Form>
        </Card>
      </div>
    </Container>
  );
};

export default AccountCreationPayment;
