import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import routes from './routes/routes';
import './App.css';
import Footer from './components/footer';
import DashboardHeader from './components/headerDashboard';
import AdminPannelHeader from './components/headerAdminPannel';
import AdminPannelSidebar from './components/sidebarAdminPannel';

const AppContent = () => {
  const location = useLocation();
  const isAdminRoute = location.pathname.startsWith('/admin');

  const renderRoutes = (routesArray) =>
    routesArray.map(({ path, element: Element }) => (
      <Route key={path} path={path} element={<Element />} />
    ));

  return (
    <div className="App">

      {isAdminRoute ? <AdminPannelHeader /> : <DashboardHeader />}

      <div>
        {isAdminRoute && <AdminPannelSidebar />}

        <main style={{ padding: isAdminRoute ? '20px' : '' }}>
          <Routes>
          
            {renderRoutes(routes.public)}

            {renderRoutes(routes.dashboard)}

            {renderRoutes(routes.admin)}
          </Routes>
        </main>
      </div>

      {!isAdminRoute && <Footer />}
    </div>
  );
};

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;
