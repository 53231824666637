import React from 'react';
import { Container, Row, Col} from 'react-bootstrap';

const AboutMe = () => {
    return (
        <>
            <Container fluid className="">
                <Container className='pt-5'>
                    <Row>
                        <Col md={12} className="d-flex flex-column justify-content-start">
                            <h2 className="fw-bold" style={{ textAlign: 'left'}}>About Me - Levonne Forsten</h2>
                            <p style={{ textAlign: 'justify' , maxWidth:'100%'}}>
                            Hi, I’m Levonne Forsten, one of the founders of TL Enterprise Institute—and someone who knows what it means to start from nothing and build something meaningful. I’ve always had an entrepreneurial spirit, driven by curiosity, creativity, and an unstoppable desire to succeed. But I also know what it’s like to face uncertainty, self-doubt, and obstacles along the way. My journey into entrepreneurship began with a simple idea and a lot of determination. Over the years, I’ve launched and scaled multiple businesses, learning firsthand what works—and what doesn’t. That experience taught me the value of having a clear plan, solid support, and someone in your corner who’s been through it all. That’s why I created TL Enterprise Institute—to share what I’ve learned and help others take that leap confidently. I believe in making business ownership accessible, especially for those who have the passion but may lack the resources or experience to get started. With TL Enterprise Institute, I’m committed to offering more than just ideas—I’m offering a partnership built on trust, collaboration, and shared success. Whether you’re starting your first business or looking to grow an existing one, I’m here to help you make it happen. For me, entrepreneurship isn’t just work. It’s not an obsession, it’s a passion. And I’m excited to share that passion with you. Let’s build something amazing together.
                            </p>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </>
    );
};

export default AboutMe;
