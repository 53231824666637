import React, { useState } from "react";
import { Carousel } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

const Review = () => {
  // Testimonials array
  const testimonials = [
    {
      name: "Sarah K.",
      description:
        "TL Enterprise Institute has completely transformed the way I approach business. The plug-and-play business model made it incredibly easy to get started, and the personalized mentorship gave me the confidence to take my idea to the next level. I felt supported every step of the way, and the results speak for themselves—I’m now running a profitable business I’m proud of! Highly recommend it to anyone ready to start their entrepreneurial journey!",
    },
    {
      name: "John D.",
      description:
        "I can’t believe how much easier starting my business was with TL Enterprise Institute. They took the guesswork out of everything and provided a seamless process that let me focus on growing my brand. The support team is exceptional, and the results exceeded my expectations. Thank you for making entrepreneurship accessible to everyone!",
    },
    {
      name: "Emily R.",
      description:
        "Working with TL Enterprise Institute has been a game-changer for me. They provided me with a comprehensive business model and the resources I needed to succeed. The mentorship is phenomenal—I learned so much and am now running a successful business with confidence. I can’t thank them enough for their support.",
    },
  ];

  // Carousel state
  const [index, setIndex] = useState(0);

  // Handle carousel navigation
  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  const handleNext = () => {
    setIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
  };

  const handlePrev = () => {
    setIndex((prevIndex) =>
      prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1
    );
  };

  return (
    <div className="text-center mb-5">
      <h2 style={{ fontWeight: "700" }}>Testimonials</h2>
      <p className="text-muted">What our clients say about us</p>

      <Carousel
        activeIndex={index}
        onSelect={handleSelect}
        indicators={false}
        interval={null}
        controls={false} // Disable default controls to use custom buttons
        className="mt-4"
      >
        {testimonials.map((testimonial, i) => (
          <Carousel.Item key={i}>
            <div
              className="d-flex align-items-center justify-content-center"
              style={{
                paddingLeft: "10px",
                paddingRight: "10px",
              }}
            >
              {/* Content */}
              <div className="text-center">
                <h5 className="mt-3">{testimonial.name}</h5>
                <p
                  className="text-muted px-3"
                  style={{ textAlign: "center", maxWidth: "650px" }}
                >
                  {testimonial.description}
                </p>
              </div>
            </div>
          </Carousel.Item>
        ))}
      </Carousel>

      {/* Navigation Buttons */}
      <div className="d-flex justify-content-center mt-4">
        <button
          className="btn btn-warning rounded-circle me-3"
          onClick={handlePrev}
          style={{
            backgroundColor: "#d9c091",
            color: "#fff",
            border: "none",
            width: "40px",
            height: "40px",
          }}
        >
          &#8249;
        </button>
        <button
          className="btn btn-warning rounded-circle ms-3"
          onClick={handleNext}
          style={{
            backgroundColor: "#d9c091",
            color: "#fff",
            border: "none",
            width: "40px",
            height: "40px",
          }}
        >
          &#8250;
        </button>
      </div>
    </div>
  );
};

export default Review;
