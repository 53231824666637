import React, { useState, useEffect } from "react";
import { Table, Pagination, Button, Badge, Form, Stack, Modal, Spinner } from "react-bootstrap";
import { FaFilter, FaInfoCircle, FaUserCircle } from "react-icons/fa";
import "bootstrap/dist/css/bootstrap.min.css";
import { fetchUsers } from "../../controllers/admin_pannel/userManagmentController";
import { useNavigate } from "react-router-dom";

const AdminPannelUserManagement = () => {
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [filterText, setFilterText] = useState("");
  
  const [statusFilter, setStatusFilter] = useState(""); 
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const handleNavigate = (user) => {
    navigate("/admin/userProfile", { state: user });
  };

  const handleClearFilter = () => {
    setFilterText(""); // Clear the filter text
    setStatusFilter(""); // Clear the status filter
    setFilteredUsers(users); // Reset to the full user list
  };

  useEffect(() => {
    const loadUsers = async () => {
      setLoading(true); // Set loading to true before fetching
      try {
        const fetchedUsers = await fetchUsers();
        setUsers(fetchedUsers);
        setFilteredUsers(fetchedUsers); // Initialize filtered users
      } catch (error) {
        console.error("Error fetching users:", error);
      } finally {
        setLoading(false); // Set loading to false after fetching
      }
    };

    loadUsers();
  }, []);

  const handleApplyFilter = () => {
    let filtered = users;

    // Filter by name (case-insensitive)
    if (filterText) {
      const lowerCaseFilterText = filterText.toLowerCase(); // Convert filter text to lowercase
      filtered = filtered.filter((user) => {
        const userFullName = (user.firstName + ' ' + user.lastName).toLowerCase(); // Convert user's name to lowercase
        return userFullName.includes(lowerCaseFilterText);
      });
    }

    // Filter by status
    if (statusFilter) {
      filtered = filtered.filter((user) => user.status === statusFilter);
    }

    setFilteredUsers(filtered); 
    setShowFilterModal(false); 
  };

  const totalItems = filteredUsers.length; // Use filteredUsers for the total count
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredUsers.slice(indexOfFirstItem, indexOfLastItem); // Slice filteredUsers  

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  return (
    <div className="admin-panel pt-4">
       <style>
        {`
          .admin-panel .container {
            padding: 20px;
            padding-left: 250px;
            min-height: 80vh;
          }

          .table-container {
            background-color: #ffffff;
            border-radius: 8px;
            box-shadow: 0px 5px 15px rgba(0, 91, 187, 0.3);
            overflow-x: auto;
            padding: 20px;
          }

          .avatar {
            width: 32px;
            height: 32px;
            border-radius: 50%;
            margin-right: 10px;
          }

          .status-badge {
            padding: 6px 12px;
            border-radius: 12px;
            font-size: 0.85rem;
          }

          .pagination-container .pagination {
            color: #c59532;
          }
          .pagination-container .page-link {
            color: #c59532;
            border: 1px solid #c59532;
            background-color: transparent;
            border-radius: 5px;
          }
          .pagination-container .page-link:hover {
            background-color: #c59532 !important;
            color: #fff !important;
          }
          .pagination-container .active .page-link {
            background-color: #c59532;
            color: #fff;
            border-color: #c59532;
          }

          /* The filter popup design */
          .filter-popup {
            padding: 20px;
            background-color: #fff;
            border-radius: 10px;
            text-align: center;
          }
          .filter-popup h4 {
            font-size: 24px;
            font-weight: bold;
            margin-bottom: 20px;
          }
          .filter-popup .form-control {
            border-radius: 30px;
            padding: 10px 15px;
            border: 1px solid #ccc;
            font-size: 16px;
          }
          .filter-popup .clear-button {
            color: black;
            background-color: white;
            border: 1px solid #d6a63e;
            border-radius: 30px;
            padding: 7px 20px;
            font-size: 16px;
            font-weight: bold;
          }
          .filter-popup .clear-button:hover {
            background-color: #c59532;
            border: none;
            color: #ffffff;
          }
          .filter-popup .save-button {
            background-color: #d6a63e;
            color: #fff;
            border: none;
            max-width: 160px;
            border-radius: 20px;
            padding: 4px 16px;
          }
          .filter-popup .save-button:hover {
            background-color: #c59532;
          }

          .custom-button {
            font-size: 0.85rem;
            color: #ffffff;
            background-color: #c69e59;
            border: none;
            padding: 8px 16px;
            border-radius: 8px;
            cursor: pointer;
            transition: background-color 0.3s, box-shadow 0.3s;
            box-shadow: 0px 2px 1px #c69e59;
          }

          .custom-button:hover {
            background-color: #c69e59;
            box-shadow: 0px 2px 5px #c69e59;
          }

          @media (max-width: 992px) {
            .admin-panel .container {
              padding-left: 0;
              padding-top: 20px;
            }
            .admin-panel .table-container {
              padding: 10px;
            }
          }
          .page-title {
            font-size: 40px;
            font-weight: bold;
            margin-bottom: 30px;
            color: #000;
          }
        `}
      </style>
      <div className="idea-management-container">
        <h1 className="page-title">User Management</h1>
        <div className="container">
          <div className="actions-bar d-flex align-items-center mb-3">
            <Button className="custom-button" onClick={() => setShowFilterModal(true)}>
              <FaFilter /> Filters
            </Button>
          </div>

          <div className="table-container">
            {loading ? (
              <div className="loading-text">
                <Spinner animation="border" /> Loading...
              </div>
            ) : (
              <Table responsive hover>
                <thead>
                  <tr>
                    <th>
                      <Form.Check type="checkbox" />
                    </th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Country</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {currentItems.map((user) => ( // Use currentItems here
                    <tr key={user.id}>
                      <td>
                        <Form.Check type="checkbox" />
                      </td>
                      <td>
                        <Stack direction="horizontal" gap={2} className="align-items-center">
                          {user.profileImageUrl ? (
                            <img
                              src={user.profileImage}
                              alt="avatar"
                              className="avatar"
                            />
                          ) : (
                            <FaUserCircle size={32} color="#666" className="avatar" />
                          )}
                          <span>{user.firstName || "N/A"}</span>
                        </Stack>
                      </td>
                      <td>{user.email || "N/A"}</td>
                      <td>{user.country || "N/A"}</td>
                      <td>
                        <Badge
                          className="status-badge"
                          bg={user.status === "Won Idea" ? "success" : "secondary"}
                        >
                          {user.status || "To be discussed"}
                        </Badge>
                      </td>
                      <td>
                        <Button onClick={() => handleNavigate(user)} variant="link" className="p-0 mx-2">
                          <FaInfoCircle size={18} color="#c69e59" />
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}

            <div className="pagination-container">
              <Pagination>
                <Pagination.Prev
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  Previous
                </Pagination.Prev>
                {[...Array(totalPages)].map((_, index) => (
                  <Pagination.Item
                    className="ms-1 me-1"
                    key={index + 1}
                    active={index + 1 === currentPage}
                    onClick={() => handlePageChange(index + 1)}
                  >
                    {index + 1}
                  </Pagination.Item>
                ))}
                <Pagination.Next
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPages}
                >
                  Next
                </Pagination.Next>
              </Pagination>
            </div>
          </div>
        </div>
      </div>

      {/* Filter Modal */}
      <Modal show={showFilterModal} onHide={() => setShowFilterModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="filter-popup">
            <h4>Enter Filter Text</h4>
            <Form.Control
              type="text"
              placeholder="Type your filter by Name"
              value={filterText}
              onChange={(e) => setFilterText(e.target.value)}
            />
            <Form.Group className="mb-3" style={{ marginTop: "15px" }}>
              <Form.Label>Status</Form.Label>
              <Form.Select
                value={statusFilter}
                onChange={(e) => setStatusFilter(e.target.value)}
              >
                <option value="">Select Status</option>
                <option value="Awaiting for Idea">Awaiting for Idea</option>
                <option value="To be discussed">To be discussed</option>
                <option value="Won Idea">Won Idea</option>
                <option value="Collaboration">Collaboration</option>
              </Form.Select>
            </Form.Group>
            <div style={{ display: "flex", justifyContent: "space-between", marginTop: "20px" }}>
              <Button className="save-button" onClick={handleApplyFilter}>
                Apply Filter
              </Button>
              <Button className="clear-button" variant="outline-secondary" onClick={handleClearFilter}>
                Clear Filter
              </Button>
            </div>

          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AdminPannelUserManagement;
