import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';

const HeroAboutUs = () => {
    return (
        <>
            <Container fluid className="py-5" style={{ backgroundColor: '#f8f3e3' }}>
                <Container>
                    <Row className="text-center mb-4">
                        <Col>
                            <h1 className="fw-bold">About Us</h1>
                        </Col>
                    </Row>
                </Container>
            </Container>
            <Container fluid className="">
                <Container className='pt-5 mt-3'>
                    <Row>
                        <Col md={6} className="pe-5 d-flex justify-content-center align-items-start">
                            <Image
                                src="/images/aboutUs.png"
                                alt="Team Discussion"
                                width='100%'
                                height='70%'
                                className="rounded"
                            />
                        </Col>
                        <Col md={5} className="d-flex flex-column justify-content-start">
                            <h2 className="fw-bold" style={{ textAlign: 'left'}}>What Are We</h2>
                            <p style={{ textAlign: 'justify' , maxWidth:'100%'}}>
                                At TL Enterprise Institute, we offer a unique and accessible platform for entrepreneurs
                                who want to quickly and efficiently start a business. Our Plug-and-Play Business Model
                                provides fully developed business concepts that are ready for immediate implementation.
                                This saves entrepreneurs valuable time and reduces the risks associated with starting a
                                business from scratch.
                            </p>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </>
    );
};

export default HeroAboutUs;
