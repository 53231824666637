import React, { useState, useEffect } from "react";
import { Container, Col, Row, Form, Button, Spinner } from "react-bootstrap";
import { FaCamera, FaPlusCircle } from "react-icons/fa";
import { submitIdea } from "../../controllers/admin_pannel/ideaSubmissionController";
import { fetchCategories } from "../../controllers/categoriesController";
import { fetchNiches } from "../../controllers/nicheController";
import Notification from "../../components/pop_up/notification";
import {useNavigate } from 'react-router-dom';

const IdeaSubmissionForm = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    title: "",
    location: "Global",
    description: "",
    longDescription: "",
    category: "",
    niche: "",
    type: "Online",
  });

  const [file, setFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [categories, setCategories] = useState([]);
  const [niches, setNiches] = useState([]);
  const [filteredNiches, setFilteredNiches] = useState([]);
  const [notification, setNotification] = useState(null);

  useEffect(() => {

    const fetchData = async () => {
      try {
        const categoryData = await fetchCategories();
        setCategories(categoryData);

        const nicheData = await fetchNiches();
        setNiches(nicheData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    // Filter niches based on the selected category
    if (formData.category) {
      const filtered = niches.filter(
        (niche) => niche.categoryId === formData.category
      );
      setFilteredNiches(filtered);

      // If there are no valid niches after category change, clear 'niche' value
      if (filtered.length === 0) {
        setFormData((prev) => ({ ...prev, niche: "" }));
      }
    } else {
      setFilteredNiches([]);
    }
  }, [formData.category, niches]); // Only depend on formData.category and niches
  // Dependencies: formData.category and niches

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      setImagePreview(URL.createObjectURL(selectedFile)); // Display image preview
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.title) newErrors.title = "Title is required.";
    if (!formData.description) newErrors.description = "Description is required.";
    if (!formData.longDescription)
      newErrors.longDescription = "Long Description is required.";
    if (!formData.category) newErrors.category = "Category is required.";
    if (!formData.niche) newErrors.niche = "Niche is required.";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) return;

    setIsSubmitting(true); // Show spinner
    const result = await submitIdea(formData, file);

    setIsSubmitting(false);

    if (result.success) {
      setNotification("Successfully submitted your idea!");
      setTimeout(() => navigate('/admin/ideaManagment'), 2000);
      setFormData({
        title: "",
        location: "Global",
        description: "",
        longDescription: "",
        category: "",
        niche: "",
        type: "Online",
      });
      setFile(null);
      setImagePreview(null);
    } else {
      window.alert(); // Error alert
      setNotification("Error submitting idea: " + result.message);
    }
  };

  return (
    <div className="idea-submission-wrapper">
      <style jsx="true">{`
        .idea-submission-wrapper {
          max-width: 1000px;
          margin-left: 242px;
          padding: 20px;
        }

        .form-container {
          background: #fff;
          width: 100%;
          padding: 30px;
          border-radius: 10px;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        }

        .form-title {
          text-align: center;
          margin-bottom: 20px;
          font-weight: 600;
        }

        .upload-photo {
          width: 100px;
          height: 100px;
          border-radius: 50%;
          background: #e9ecef;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0 auto;
          position: relative;
          cursor: pointer;
        }

        .upload-photo img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          object-fit: cover;
        }

        .upload-photo .camera-icon {
          font-size: 30px;
          color: #6c757d;
        }

        .upload-photo .plus-icon {
          position: absolute;
          top: -8px;
          right: -8px;
          font-size: 18px;
          color: #c69e59;
        }

        .upload-photo-text {
          display: block;
          text-align: center;
          margin-top: 5px;
          color: #6c757d;
        }

        .form-label {
          font-weight: 500;
          margin-bottom: 6px;
          display: block;
          text-align: left;
        }

        .form-control,
        .form-select {
          border-radius: 8px;
        }

        .error-message {
          color: red;
          font-size: 12px;
        }

        .save-button {
          width: 200px;
          height: 40px;
          background: #c69e59;
          border: none;
          border-radius: 28px;
          transition: background 0.3s ease;
          margin: 20px auto 0;
          display: block;
        }

        .save-button:hover {
          background: #a88247;
        }
      `}</style>
      <Container>
        <Row className="justify-content-center">
          <div className="form-container">
            <h3 className="form-title">Idea Submission Form</h3>
            <Form onSubmit={handleSubmit}>
              <div className="mb-3 text-center">
                <div className="upload-photo">
                  {imagePreview ? (
                    <img src={imagePreview} alt="Preview" />
                  ) : (
                    <>
                      <FaCamera className="camera-icon" />
                      <FaPlusCircle className="plus-icon" />
                    </>
                  )}
                  <input
                    type="file"
                    onChange={handleFileChange}
                    style={{
                      position: "absolute",
                      width: "100px",
                      height: "100px",
                      opacity: 0,
                      cursor: "pointer",
                    }}
                  />
                </div>
                <small className="upload-photo-text">Upload Photo</small>
                {errors.file && <div className="error-message">{errors.file}</div>}
              </div>

              <Row className="mb-3">
                <Col md={6}>
                  <Form.Group>
                    <Form.Label className="form-label">Title</Form.Label>
                    <Form.Control
                      type="text"
                      name="title"
                      value={formData.title}
                      onChange={handleChange}
                      placeholder="A short, catchy title for the idea."
                    />
                    {errors.title && (
                      <div className="error-message">{errors.title}</div>
                    )}
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label className="form-label">Location</Form.Label>
                    <Form.Select
                      name="location"
                      value={formData.location}
                      onChange={handleChange}
                    >
                      <option>Global</option>
                      <option>Local</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
              <Form.Group className="mb-3">
                <Form.Label className="form-label">Description</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={2}
                  name="description"
                  value={formData.description}
                  onChange={handleChange}
                  placeholder="A quick summary of the idea (up to 250 characters)."
                />
                {errors.description && (
                  <div className="error-message">{errors.description}</div>
                )}
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label className="form-label">Long Description</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={4}
                  name="longDescription"
                  value={formData.longDescription}
                  onChange={handleChange}
                  placeholder="Full details of the idea, including goals, expected outcomes, and unique features."
                />
                {errors.longDescription && (
                  <div className="error-message">
                    {errors.longDescription}
                  </div>
                )}
              </Form.Group>

              <Row>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label className="form-label">Category</Form.Label>
                    <Form.Select
                      name="category"
                      value={formData.category}
                      onChange={handleChange}
                    >
                      <option value="" disabled>
                        Select a category
                      </option>
                      {categories.map((category) => (
                        <option key={category.id} value={category.id}>
                          {category.name}
                        </option>
                      ))}
                    </Form.Select>
                    {errors.category && (
                      <div className="error-message">{errors.category}</div>
                    )}
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label className="form-label">Niche</Form.Label>
                    <Form.Select
                      name="niche"
                      value={formData.niche}
                      onChange={handleChange}
                    >
                      <option value="" disabled>
                        Select a niche
                      </option>
                      {filteredNiches.map((niche) => (
                        <option key={niche.id} value={niche.niche}>
                          {niche.niche}
                        </option>
                      ))}
                    </Form.Select>
                    {errors.niche && (
                      <div className="error-message">{errors.niche}</div>
                    )}
                  </Form.Group>
                </Col>
              </Row>

              <Form.Group className="mb-3">
                <Form.Label className="form-label">Type</Form.Label>
                <div className="d-flex gap-3">
                  <Form.Check
                    type="radio"
                    label="Online"
                    name="type"
                    value="Online"
                    checked={formData.type === "Online"}
                    onChange={handleChange}
                  />
                  <Form.Check
                    type="radio"
                    label="Physical"
                    name="type"
                    value="Physical"
                    checked={formData.type === "Physical"}
                    onChange={handleChange}
                  />
                </div>
              </Form.Group>

              <Button
                type="submit"
                className="save-button"
                disabled={isSubmitting}
              >
                {isSubmitting ? <Spinner animation="border" size="sm" /> : "Save"}
              </Button>
            </Form>
          </div>
        </Row>
      </Container>
      {notification && (
        <Notification
          text={notification}
          onClose={() => setNotification(null)}
        />
      )}
    </div>
  );
};

export default IdeaSubmissionForm;
