import React, { useState } from "react";
import { Form, Button, Alert, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { loginUser } from "../../controllers/loginController";
import { getDocs, query, where, collection } from "firebase/firestore";
import { db } from "../../firebaseConfig";
import UserService from "../../controllers/currentUserId";

const Login = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [formErrors, setFormErrors] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [id]: value }));
  };

  const validateForm = () => {
    let errors = {};
    let isValid = true;

    // Email validation
    if (!formData.email) {
      isValid = false;
      errors.email = "Email is required.";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      isValid = false;
      errors.email = "Email is not valid.";
    }

    // Password validation
    if (!formData.password) {
      isValid = false;
      errors.password = "Password is required.";
    } else if (formData.password.length < 6) {
      isValid = false;
      errors.password = "Password must be at least 6 characters long.";
    }

    setFormErrors(errors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage("");
    setFormErrors({});
  
    if (!validateForm()) {
      return; // Exit if the form is invalid
    }
  
    try {
      setLoading(true);
      const role = await loginUser(formData.email, formData.password);
  
      if (role === "admin") {
        navigate("/admin");
      } else {
        // Get the current user ID
        const userId = UserService.getCurrentUserId();
  
        // Query the `transactions` collection for the required conditions
        const transactionsRef = collection(db, "transactions");
        const q = query(
          transactionsRef,
          where("userId", "==", userId),
          where("status", "==", "success"),
          where("type", "==", "Account Creation")
        );
        const querySnapshot = await getDocs(q);
  
        // If no matching transaction, show an error and redirect to /accountCreationPayment after 1 second
        if (querySnapshot.empty) {
          setErrorMessage("Account creation payment not found or incomplete.");
  
          // Delay navigation to /accountCreationPayment for 1 second
          setTimeout(() => {
            navigate("/accountCreationPayment");
          }, 1000);
        } else {
          navigate("/"); // Proceed to the "/" route
        }
      }
    } catch (error) {
      setErrorMessage("Invalid email or password. Please try again.");
    } finally {
      setLoading(false);
    }
  };
  
  return (
    <Container className="d-flex justify-content-center align-items-center min-vh-100">
      <div className="login-container">
        <h2 className="text-center mb-4">Login</h2>

        {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}

        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="email">
            <Form.Label className="form-label">Email</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter your email"
              onChange={handleInputChange}
              isInvalid={!!formErrors.email}
            />
            <Form.Control.Feedback type="invalid">
              {formErrors.email}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="password">
            <Form.Label className="form-label">Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Enter your password"
              onChange={handleInputChange}
              isInvalid={!!formErrors.password}
            />
            <Form.Control.Feedback type="invalid">
              {formErrors.password}
            </Form.Control.Feedback>
          </Form.Group>

          <Button
            variant="primary"
            type="submit"
            className="submit-btn"
            disabled={loading}
          >
            {loading ? "Processing..." : "Login"}
          </Button>
        </Form>

        <div className="text-center mt-3">
          Don't have an account?{" "}
          <a href="/register" className="signup-link">
            Sign up
          </a>
        </div>
      </div>

      <style jsx>{`
        .login-container {
          width: 100%;
          max-width: 500px;
          padding: 2.5rem;
          background-color: #ffffff;
          border-radius: 15px;
          box-shadow: 0 6px 18px rgba(0, 0, 0, 0.1);
        }
        .form-label {
          display: block;
          font-weight: 600;
          margin-bottom: 0.3rem;
          color: #333;
          text-align: left;
        }
        .submit-btn {
          width: 30%;
          background-color: #c69e59;
          border: none;
          padding: 0.75rem;
          font-weight: bold;
          font-size: 1rem;
          margin-top: 1rem;
          margin-left: auto;
          margin-right: auto;
          display: block;
        }
        .submit-btn:hover {
          background-color: #b07e46;
        }
        .signup-link {
          color: #c69e59;
          text-decoration: none;
          font-weight: bold;
        }
        .signup-link:hover {
          text-decoration: underline;
        }
      `}</style>
    </Container>
  );
};

export default Login;
