import React, { useState, useEffect } from "react";
import { Container, Navbar, Nav } from "react-bootstrap";
import { FaBell } from "react-icons/fa";
import { BsPerson } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import { auth } from "../firebaseConfig";
import { signOutUser } from "../controllers/signOutController";
import { collection, query, where, onSnapshot } from "firebase/firestore";
import { db } from "../firebaseConfig";

const DashboardHeader = () => {
  const [userId, setUserId] = useState(null);
  const [profileImageUrl, setProfileImageUrl] = useState(null);
  const [hasPaymentCompleted, setHasPaymentCompleted] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const navigate = useNavigate();

  // Real-time payment status listener
  const listenToPaymentStatus = (uid) => {
    const transactionsRef = collection(db, "transactions");
    const q = query(
      transactionsRef,
      where("userId", "==", uid),
      where("status", "==", "success"),
      where("type", "==", "Account Creation")
    );

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      setHasPaymentCompleted(!querySnapshot.empty);
      setIsLoaded(true); // Mark as loaded
    });

    return unsubscribe;
  };

  // Real-time profile listener
  const listenToProfileImage = (uid) => {
    const userRef = collection(db, "users");
    const q = query(userRef, where("userId", "==", uid));

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      if (!querySnapshot.empty) {
        const userData = querySnapshot.docs[0].data();
        setProfileImageUrl(userData.profileImage || null);
      }
      setIsLoaded(true); // Mark as loaded
    });

    return unsubscribe;
  };

  useEffect(() => {
    const unsubscribeAuth = auth.onAuthStateChanged((user) => {
      if (user) {
        setUserId(user.uid);

        const unsubscribePayment = listenToPaymentStatus(user.uid);
        const unsubscribeProfile = listenToProfileImage(user.uid);

        setIsLoaded(true); // Mark as loaded after initializing listeners

        return () => {
          unsubscribePayment();
          unsubscribeProfile();
        };
      } else {
        setUserId(null);
        setProfileImageUrl(null);
        setHasPaymentCompleted(false);
        setIsLoaded(true); // Mark as loaded for logged-out state
      }
    });

    return () => unsubscribeAuth();
  }, []);

  const handleSignOut = async () => {
    try {
      await signOutUser();
      navigate("/login");
    } catch (error) {
      alert(error.message);
    }
  };

  return (
    <header>
      <style>{`
        .navbar-custom {
          background-color: #f8f3e3;
          padding: 10px 0;
          box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
          position: sticky;
          top: 0;
          z-index: 1000;
        }

        .brand-container {
          display: flex;
          align-items: center;
          flex: 0 0 10%;
        }

        .brand-text-tl {
          font-weight: bold;
          color: #000000;
          font-size: 1.5rem;
          margin-right: 5px;
        }

        .brand-text-institute {
          font-weight: 400;
          color: #000000;
          font-size: 1.1rem;
        }

        .nav-container {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          flex: 0 0 100%;
          gap: 10px;
        }

        .nav-link-text {
          position: relative;
          text-decoration: none;
          color: #000;
          font-size: 1rem;
          font-weight: 500;
          padding: 2px 0;
          transition: color 0.3s ease-in-out;
        }

        .nav-link-text::after {
          content: '';
          position: absolute;
          left: 0;
          bottom: -2px;
          width: 0;
          height: 2px;
          background-color: #c69e59;
          transition: width 0.3s ease-in-out;
        }

        .nav-link-text:hover::after {
          width: 100%;
        }

        .bell-icon {
          background-color: #c69e59;
          border-radius: 50%;
          width: 50px;
          height: 50px;
          color: white;
          font-size: 1.5rem;
          cursor: pointer;
          transition: background-color 0.3s ease;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .bell-icon:hover {
          background-color: #a48045;
        }

        .profile-image-container {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          overflow: hidden;
          border: 3px solid #c69e59;
          margin-left: 15px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .profile-image {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .profile-loader {
          width: 100%;
          height: 100%;
          background-color: #e0e0e0;
          animation: pulse 1.5s infinite;
        }

        @keyframes pulse {
          0% {
            background-color: #e0e0e0;
          }
          50% {
            background-color: #f0f0f0;
          }
          100% {
            background-color: #e0e0e0;
          }
        }

        .default-icon {
          font-size: 1.5rem;
          color: #c69e59;
        }

        #get-started-button {
          background-color: #c69e59;
          color: white;
          padding: 8px 16px;
          border: none;
          border-radius: 4px;
          cursor: pointer;
          font-size: 1rem;
          transition: background-color 0.3s ease;
        }

        #get-started-button:hover {
          background-color: #a48045;
        }

        .notification {
          position: fixed;
          bottom: 20px;
          right: 20px;
          width: 300px;
          height: 100px;
          background: linear-gradient(135deg, #56ab2f, #a8e063);
          color: white;
          border-radius: 8px;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          padding: 15px;
          font-size: 1rem;
          font-weight: bold;
          animation: fadeInOut 5s forwards;
        }

        .notification-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        .notification-close {
          font-size: 1.2rem;
          color: white;
          cursor: pointer;
          background: none;
          border: none;
        }

        @keyframes fadeInOut {
          0% { opacity: 0; }
          10% { opacity: 1; }
          90% { opacity: 1; }
          100% { opacity: 0; }
        }
      `}</style>
      <Navbar expand="lg" className="navbar-custom">
        <Container className="navbar-collapse">
          <div className="brand-container">
            <Navbar.Brand href="/" style={{ padding: 0 }}>
              <span className="brand-text-tl">TL</span>
              <span className="brand-text-institute">Enterprise Institute</span>
            </Navbar.Brand>
          </div>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="nav-container">
            <Nav.Link href="/" className="nav-link-text">
                Home
              </Nav.Link>
              <Nav.Link href="/aboutUs" className="nav-link-text">
                About Us
              </Nav.Link>
              <Nav.Link href="/#contactUs" className="nav-link-text">
                Blog
              </Nav.Link>
              <Nav.Link href="/blog" className="nav-link-text">
                Contact
              </Nav.Link>
              <Nav.Link href="/#onboarding" className="nav-link-text">
                FAQS
              </Nav.Link>
              
              {isLoaded && userId && hasPaymentCompleted ? (
                <>
                  <Nav.Link href="/dashboard" className="nav-link-text">
                    Dashboard
                  </Nav.Link>
                  <div className="bell-icon">
                    <FaBell />
                  </div>
                  <div className="profile-image-container">
                    <Link to="/dashboard/userProfile">
                      {profileImageUrl ? (
                        <img
                          src={profileImageUrl}
                          alt="Profile"
                          className="profile-image"
                        />
                      ) : (
                        <div className="default-icon">
                          <BsPerson />
                        </div>
                      )}
                    </Link>
                  </div>
                  <button id="get-started-button" onClick={handleSignOut}>
                    Log Out
                  </button>
                </>
              ) : (
                <>
                  {isLoaded && userId && hasPaymentCompleted ? (
                    <>
                    </>
                  ) : (
                    <>
                      {isLoaded && (!userId || !hasPaymentCompleted) && (
                        <button
                          id="get-started-button"
                          onClick={() => navigate("/register")}
                        >
                          Get Started
                        </button>
                      )}
                    </>
                  )}

                </>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
};

export default DashboardHeader;
