import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { FaRocket, FaExclamationTriangle, FaHandHoldingUsd, FaHandsHelping } from 'react-icons/fa';

const WhyChooseTL = () => {
  const styles = {
    container: {
      padding: '2rem 0',
    },
    title: {
      textAlign: 'center',
      marginBottom: '1.5rem',
      fontSize: '2rem',
      fontWeight: '700',
    },
    iconWrapper: {
      backgroundColor: '#EDD086',
      borderRadius: '5%',
      padding: '20px',
      display: 'inline-block',
      marginBottom: '1rem',
    },
    icon: {
      fontSize: '2rem',
      color: '#000',
    },
    cardTitle: {
      fontWeight: '600',
      fontSize: '1.25rem',
    },
    cardText: {
      fontSize: '0.95rem',
      color: '#555',
    },
  };

  return (
    <Container style={styles.container}>
      <h2 style={styles.title}>Why Choose TL Enterprise Institute?</h2>
      <Row className="g-4">
        <Col md={3}>
          <Card className="text-center shadow-sm border-0">
            <Card.Body>
              <div style={styles.iconWrapper}>
                <FaRocket style={styles.icon} />
              </div>
              <Card.Title style={styles.cardTitle}>Quick Launch</Card.Title>
              <Card.Text style={styles.cardText}>
                Avoid long development processes and start selling right away.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={3}>
          <Card className="text-center shadow-sm border-0">
            <Card.Body>
              <div style={styles.iconWrapper}>
                <FaExclamationTriangle style={styles.icon} />
              </div>
              <Card.Title style={styles.cardTitle}>Lower Risks</Card.Title>
              <Card.Text style={styles.cardText}>
                Our proven concepts minimize the chances of failure.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={3}>
          <Card className="text-center shadow-sm border-0">
            <Card.Body>
              <div style={styles.iconWrapper}>
                <FaHandHoldingUsd style={styles.icon} />
              </div>
              <Card.Title style={styles.cardTitle}>Affordable & Efficient</Card.Title>
              <Card.Text style={styles.cardText}>
                Costs are lower than building a business from the ground up.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={3}>
          <Card className="text-center shadow-sm border-0">
            <Card.Body>
              <div style={styles.iconWrapper}>
                <FaHandsHelping style={styles.icon} />
              </div>
              <Card.Title style={styles.cardTitle}>Tailored Support</Card.Title>
              <Card.Text style={styles.cardText}>
                Benefit from our expertise and ongoing guidance through mentorship.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default WhyChooseTL;
