import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import Notification from "./notification";

const PitchCollaborationPopUp = ({ show, handleClose, onSubmit }) => {
  const [pitch, setPitch] = useState("");
  const [notification, setNotification] = useState(null); 

  const handlePitchChange = (e) => {
    setPitch(e.target.value);
  };

  const handleSubmit = () => {
    if (onSubmit) {
      onSubmit(pitch);
    }
    setNotification("Collabartion Request sent successfully "); 
    handleClose();
  };

  return (
    <>
      <style>
        {`
          .popup-container {
            text-align: center;
            padding: 20px;
            font-family: Arial, sans-serif;
          }
          .popup-header {
            font-size: 20px;
            font-weight: bold;
            margin-bottom: 20px;
          }
          .popup-footer {
            font-size: 16px;
            color: #555;
            margin-top: 10px;
          }
          .textarea-container {
            margin: 20px 0;
            text-align: start;
          }
          .textarea-container label {
            font-weight: bold;
            font-size: 16px;
            margin-bottom: 10px;
            display: block;
          }
          .textarea-container textarea {
            width: 100%;
            border: 1px solid #ccc;
            border-radius: 8px;
            padding: 10px;
            font-size: 14px;
            resize: none;
          }
          .submit-button {
            background-color: #27ae60;
            color: white;
            border: none;
            padding: 10px 20px;
            border-radius: 5px;
            font-size: 16px;
            font-weight: bold;
            box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
          }
          .submit-button:hover {
            opacity: 0.9;
          }
        `}
      </style>

      <Modal show={show} onHide={handleClose} centered>
        <div className="popup-container">
          <div className="popup-header">Write Your Pitch for Collaboration</div>
          <div className="textarea-container">
            <label htmlFor="pitch">Your Pitch:</label>
            <textarea
              id="pitch"
              rows="5"
              value={pitch}
              onChange={handlePitchChange}
              placeholder="Write your collaboration pitch here..."
            />
          </div>
          <Button className="submit-button" onClick={handleSubmit}>
            Submit
          </Button>
        </div>
      </Modal>
      {notification && (
        <Notification
          text={notification}
          onClose={() => setNotification(null)}
        />
      )}
    </>
  );
};

export default PitchCollaborationPopUp;
