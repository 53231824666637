import React from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { FaEnvelope, FaInstagram, FaTwitter } from "react-icons/fa";

const Footer = () => (
  <footer className="footer-section">
    <style>{`
      .footer-section {
        padding: 40px;
        border-top: 1px solid #e7e7e7;
        box-shadow: 0 -1px 8px rgba(0, 0, 0, 0.05);
        display: flex;
        justify-content: center;
        width: 100%;
        margin-top: auto;
      }

      .footer-container {
        max-width: 1100px;  
        width: 100%;
        text-align: left; /* Align text to the left */
      }

      .footer-heading {
        font-size: 1.8rem;
        font-weight: bold;
        color: #333;
        margin-bottom: 15px;
      }

      .footer-subtext {
        color: #666;
        font-size: 1rem;
        margin-bottom: 20px;
        line-height: 1.6;
      }

      .contact-info {
        display: flex;
        align-items: center;
        font-size: 1rem;
        color: #333;
        margin-bottom: 10px;
      }

      .contact-info-icon {
        color: #c69e59;
        margin-right: 10px;
      }

      .social-icons {
        display: flex;
        gap: 15px;
        margin-top: 20px;
      }

      .social-icon {
        font-size: 1.5rem;
        color: #666;
        transition: color 0.3s;
      }

      .social-icon:hover {
        color: #c69e59;
      }

      .form-input {
        border-radius: 20px;
        border: 1px solid #ddd;
        padding: 10px 15px;
        font-size: 1rem;
        margin-bottom: 15px;
        transition: border-color 0.3s;
      }

      .form-input:focus {
        border-color: #c69e59;
        box-shadow: none;
      }

      .input-group-text-custom {
        background-color: #fff;
        border: 1px solid #ddd;
        border-right: none;
        border-radius: 20px 0 0 20px;
        color: #666;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
      }

      .submit-button {
        background-color: #c69e59;
        border: none;
        color: white;
        font-weight: 600;
        padding: 10px 30px;
        border-radius: 20px;
        transition: background-color 0.3s;
        width: 100%;
      }

      .submit-button:hover {
        background-color: #a48045;
      }

      /* Add spacing between the text section and form section */
      .footer-text-section {
        margin-bottom: 40px; /* Add space between text and form */
      }

      /* Responsive Design */
      @media (max-width: 768px) {
        .footer-container {
          text-align: center;
        }
        .social-icons {
          justify-content: center;
        }
        .footer-heading {
          font-size: 1.5rem;
        }
        .contact-info, .footer-subtext {
          justify-content: center;
        }
      }
    `}</style>

    <Container className="footer-container">
      <Row>
        <Col md={6} className="footer-text-section">
          <h2 className="footer-heading">Let’s get in touch!</h2>
          <p className="footer-subtext">
            We’re here to answer questions, provide support, and guide you through our platform.
          </p>
          <div className="contact-info">
            <FaEnvelope className="contact-info-icon" />
            <span>tlenterpriseinstitute@gmail.com</span>
          </div>
          <div className="social-icons">
            <a href="https://www.instagram.com/tlenterpriseinstitute/" className="social-icon" target="_blank" rel="noopener noreferrer" aria-label="Instagram">
              <FaInstagram />
            </a>

            <a href="https://x.com/TLenterpriseInt" className="social-icon" target="_blank" rel="noopener noreferrer" aria-label="Twitter">
              <FaTwitter />
            </a>
          </div>
        </Col>
        <Col md={6}>
          <Form>
            <Form.Group controlId="formEmail" className="mb-3">
              <div className="input-with-icon">
                <FaEnvelope className="input-icon" />
                <Form.Control type="text" placeholder="Email" className="form-input" />
              </div>
              <style>
                {`
                  .input-with-icon {
                    position: relative;
                  }

                  .input-icon {
                    position: absolute;
                    left: 10px;
                    top: 50%;
                    transform: translateY(-50%);
                    color: #888;
                    pointer-events: none;
                  }

                  .form-input {
                    padding-left: 35px;
                  }
                `}
              </style>
            </Form.Group>
            <Form.Group controlId="formName">
              <Form.Control type="text" placeholder="Write your message" className="form-input" />
            </Form.Group>
            <Button type="submit" className="submit-button">Submit</Button>
          </Form>
        </Col>
      </Row>
    </Container>
  </footer>
);

export default Footer;
