import React from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';

const GetInTouch = () => {
  const styles = {
    title: {
      fontSize: '2rem',
      fontWeight: '700',
      textAlign: 'left',
    },
    highlight: {
      color: '#d4a657',
    },
    description: {
      marginBottom: '1.5rem',
      color: '#555',
      textAlign: 'left',
    },
    form: {
      maxWidth: '400px',
      display: 'flex',
      flexDirection: 'column', // Ensures children are stacked vertically
    },
    input: {
      marginBottom: '1rem',
      borderRadius: '8px',
    },
    button: {
      backgroundColor: '#d4a657',
      borderColor: '#d4a657',
      borderRadius: '8px',
      padding: '0.5rem 1.5rem',
      fontWeight: '600',
      marginLeft: 'auto', // This pushes the button to the right
    },
    mapContainer: {
      borderRadius: '8px',
      overflow: 'hidden',
      height: '100%',
    },
  };

  return (
    <Container className="py-5">
      <Row className="align-items-left">
        <Col md={6}>
          <h2 style={styles.title}>
            Get in <span style={styles.highlight}>Touch</span>
          </h2>
          <p style={styles.description}>
            Would you like to learn more about how TL Enterprise Institute can help turn your entrepreneurial dreams into reality? 
            Visit our website at 
            <br />
            (<a href="https://tenterpriseinstitute.com" target="_blank" rel="noopener noreferrer">https://tenterpriseinstitute.com</a>) 
            <br />
            or contact us for more information!
          </p>
          <Form style={styles.form}>
            <Form.Group>
              <Form.Control type="text" placeholder="Name *" style={styles.input} />
            </Form.Group>
            <Form.Group>
              <Form.Control type="email" placeholder="Email *" style={styles.input} />
            </Form.Group>
            <Form.Group>
              <Form.Control type="text" placeholder="Phone number" style={styles.input} />
            </Form.Group>
            <Form.Group>
              <Form.Control as="textarea" rows={4} placeholder="Write Your Message Here..." style={styles.input} />
            </Form.Group>
            <Button type="submit" style={styles.button}>
              Send
            </Button>
          </Form>
        </Col>
        <Col md={6}>
          <div style={styles.mapContainer}>
          <iframe
  title="Google Map"
  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d57862.59828917484!2d55.26393130000001!3d25.1971979!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f4288e9555555%3A0xdcd0d5dd6cda7b6b!2sDowntown%20Dubai!5e0!3m2!1sen!2sae!4v1670000000000"
  width="100%"
  height="600"
  style={{ border: 0 }}
  allowFullScreen=""
  loading="lazy"
></iframe>

          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default GetInTouch;
