import React, { useState } from 'react';
import { Accordion, Container } from 'react-bootstrap';

const FrequentlyQuestions = () => {
  const [activeKey, setActiveKey] = useState(null);

  const handleToggle = (key) => {
    setActiveKey(activeKey === key ? null : key);
  };

  return (
    <>
      <style>
        {`
          .faq-container {
            margin-top: 3rem;
            margin-bottom: 3rem;
          }

          .faq-title {
            text-align: left;
            color: #c69e59;
            font-weight: bold;
            font-size: 2rem;
            margin-bottom: 2rem;
          }

          .faq-card {
            border: none;
            margin-bottom: 1.5rem;
            border-radius: 8px;
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
          }

          .faq-header {
            font-weight: bold;
            font-size: 1.2rem;
            padding: 1rem;
            cursor: pointer;
            border-radius: 8px;
            transition: background-color 0.3s ease, color 0.3s ease;
            text-align: left;
          }

          .faq-header-closed {
            background-color: #f8f3e3;
            color: #333;
          }

          .faq-header-open {
            background-color: #d6d6d6;
            color: #333;
          }

          .faq-header:hover {
            background-color: #e0e0e0;
          }

          .faq-body {
            color: #444;
            font-size: 1rem;
            padding: 1.25rem;
            background-color: #fff;
            border-top: 1px solid #e0e0e0;
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
            line-height: 1.6;
            text-align: left;
          }

          .faq-body p {
            margin-bottom: 1rem;
          }

          .faq-body ul {
            margin-left: 1.5rem;
          }

          .faq-body ul li {
            margin-bottom: 0.5rem;
          }

          .faq-body strong {
            font-weight: bold;
            color: #333;
          }

          .faq-body a {
            color: #c69e59;
            text-decoration: underline;
          }
        `}
      </style>

      <Container className="faq-container">
        <h2 className="faq-title">Frequently Asked Questions</h2>

        <Accordion activeKey={activeKey} onSelect={handleToggle}>
          <Accordion.Item eventKey="0" className="faq-card">
            <Accordion.Header
              className={`faq-header ${activeKey === '0' ? 'faq-header-open' : 'faq-header-closed'}`}
            >
              Plug-and-Play Business Model - TL Enterprise Institute
            </Accordion.Header>
            <Accordion.Body className="faq-body">
              <p>
                At <strong>TL Enterprise Institute</strong>, we offer a unique and accessible platform for entrepreneurs who want to quickly and efficiently start a business.
                Our Plug-and-Play Business Model provides fully developed business concepts that are ready for immediate implementation, saving time and reducing risks.
              </p>
              <h4>How Does It Work?</h4>
              <ul>
                <li><strong>Ready-to-Use Business Concepts:</strong> Pre-developed models with marketing strategies and revenue streams.</li>
                <li><strong>Immediate Implementation:</strong> Tools, branding, and legal documentation provided for quick setup.</li>
                <li><strong>Mentorship for Growth:</strong> Expert guidance to help you launch and scale.</li>
                <li><strong>Flexible and Scalable:</strong> Customizable and designed for growth across markets.</li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="1" className="faq-card">
            <Accordion.Header
              className={`faq-header ${activeKey === '1' ? 'faq-header-open' : 'faq-header-closed'}`}
            >
              Benefits of the Plug-and-Play Business Model
            </Accordion.Header>
            <Accordion.Body className="faq-body">
              <ul>
                <li><strong>Quick and Easy Setup:</strong> Launch your business without lengthy development.</li>
                <li><strong>Reduced Risk:</strong> Proven and tested concepts minimize chances of failure.</li>
                <li><strong>Lower Startup Costs:</strong> Affordable solutions with pre-built systems.</li>
                <li><strong>Expert Mentorship:</strong> Ongoing guidance ensures tailored success.</li>
                <li><strong>Scalable and Flexible:</strong> Expand into new markets with ease.</li>
                <li><strong>Focus on Growth:</strong> Concentrate on scaling instead of building from scratch.</li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="2" className="faq-card">
            <Accordion.Header
              className={`faq-header ${activeKey === '2' ? 'faq-header-open' : 'faq-header-closed'}`}
            >
              How to Get Started with TL Enterprise Institute?
            </Accordion.Header>
            <Accordion.Body className="faq-body">
              <ol>
                <li><strong>Submit Your Information:</strong> Fill out your details and purchase a ticket on our website.</li>
                <li><strong>Receive Your Business Idea:</strong> A tailored concept will be delivered to you.</li>
                <li><strong>Decide Your Next Step:</strong> Accept, decline, or collaborate on the idea provided.</li>
                <li><strong>Launch and Grow:</strong> Use provided resources to build and scale effectively.</li>
              </ol>
              <p>
                <strong>Why Choose TL Enterprise Institute?</strong> We provide a quick, affordable, and low-risk approach to starting your business with expert guidance every step of the way.
              </p>
              <p>
                Visit our website at <a href="https://tlenterpriseinstitute.com" target="blank">https://tlenterpriseinstitute.com</a> for more information.
              </p>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Container>
    </>
  );
};

export default FrequentlyQuestions;
