import Home from '../pages/HomePage';
import Login from '../pages/auth/LoginPage';
import Register from '../pages/auth/RegisterPage';
import AccountCreationPayment from '../pages/auth/AccountCreationPayemnt';
import UserDashboard from '../pages/dashboard.js/UserDashboard';
import IdeaProposalPage from '../pages/dashboard.js/IdeaPropsal';
import PaymentConfirmationPage from '../pages/dashboard.js/PaymentConfirmation';
import UserProfilePage from '../pages/dashboard.js/UserProfile';
import MainAdminPannelPage from '../pages/admin_pannel/MainAdminPannel';
import AdminPannelUserManagement from '../pages/admin_pannel/userManagment';
import AdminPannelUserProfilePage from '../pages/admin_pannel/userProfile';
import IdeaSubmissionForm from '../pages/admin_pannel/IdeaSubmissionForm';
import AdminPannelSettings from '../pages/admin_pannel/Settings';
import AdminPannelIdeaManagement from '../pages/admin_pannel/IdeaManagment';
import EditIdeaSubmissionForm from '../pages/admin_pannel/editIdea';
import AdminProfilePage from '../pages/admin_pannel/adminProfile';
import PaymentPage from '../pages/PaymentForm';
import AboutUs from '../pages/AboutUs';
import Blog from '../pages/Blog';

const routes = {
  public: [
    { path: "/", element: Home },
    { path: "/payment", element: PaymentPage },
    { path: "/login", element: Login },
    { path: "/register", element: Register },
    { path: "/aboutUs", element: AboutUs },
    { path: "/blog", element: Blog },
    { path: "/accountCreationPayment", element: AccountCreationPayment },
  ],
  dashboard: [
    { path: "/dashboard", element: UserDashboard },
    { path: "/dashboard/ideaPropsal", element: IdeaProposalPage },
    { path: "/dashboard/paymentConfirmation", element: PaymentConfirmationPage },
    { path: "/dashboard/userProfile", element: UserProfilePage },
  ],
  admin: [
    { path: "/admin", element: MainAdminPannelPage },
    { path: "/admin/userManagment", element: AdminPannelUserManagement },
    { path: "/admin/userProfile", element: AdminPannelUserProfilePage },
    { path: "/admin/ideaSubmission", element: IdeaSubmissionForm },
    { path: "/admin/settings", element: AdminPannelSettings },
    { path: "/admin/ideaManagment", element: AdminPannelIdeaManagement },
    { path: "/admin/editIdeaForm", element: EditIdeaSubmissionForm },
    { path: "/admin/profile", element: AdminProfilePage },
  ],
};


export default routes;
