import React, { useState, useEffect } from 'react';
import { Container, Col, Row, Form, Button } from 'react-bootstrap';
import { FaCamera } from 'react-icons/fa';
import { useLocation, useNavigate } from 'react-router-dom';
import { doc, updateDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { db } from '../../firebaseConfig'; // Adjust path to your Firebase config file
import { fetchCategories } from "../../controllers/categoriesController";
import { fetchNiches } from "../../controllers/nicheController";
import Notification from '../../components/pop_up/notification';

const EditIdeaSubmissionForm = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { idea } = location.state || {};
  const [categories, setCategories] = useState([]);
  const [niches, setNiches] = useState([]);
  const [filteredNiches, setFilteredNiches] = useState([]);
  const [notification, setNotification] = useState(null); 

  // State for form fields and image
  const [formData, setFormData] = useState({
    title: idea?.title || '',
    location: idea?.location || 'Global',
    description: idea?.description || '',
    longDescription: idea?.longDescription || '',
    category: idea?.category || '',
    niche: idea?.niche || '',
    type: idea?.type || 'Online',
    image: idea?.ideaImage || '',
  });
  const [newImage, setNewImage] = useState(null); // New image file

  useEffect(() => {
    const fetchData = async () => {
      try {
        const categoryData = await fetchCategories();
        setCategories(categoryData);

        const nicheData = await fetchNiches();
        setNiches(nicheData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    // Filter niches based on the selected category
    if (formData.category) {
      const filtered = niches.filter(
        (niche) => niche.categoryId === formData.category
      );
      setFilteredNiches(filtered);

      // If there are no valid niches after category change, clear 'niche' value
      if (filtered.length === 0) {
        setFormData((prev) => ({ ...prev, niche: "" }));
      }
    } else {
      setFilteredNiches([]);
    }
  }, [formData.category, niches]); 

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setNewImage(file);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!idea?.id) {
      setNotification('Document ID is missing.');
      return;
    }

    let updatedData = { ...formData };

    try {
      if (newImage) {
        // Upload new image to Firebase Storage
        const storage = getStorage();
        const storageRef = ref(storage, `images/${Date.now()}_${newImage.name}`);
        const uploadTask = uploadBytesResumable(storageRef, newImage);

        // Wait for upload to complete
        const snapshot = await new Promise((resolve, reject) => {
          uploadTask.on(
            'state_changed',
            null,
            (error) => reject(error),
            () => resolve(uploadTask.snapshot)
          );
        });

        // Get download URL
        const downloadURL = await getDownloadURL(snapshot.ref);
        updatedData.image = downloadURL; // Update the image URL
      }

      // Update Firestore document
      const docRef = doc(db, 'ideas', idea.id);
      await updateDoc(docRef, updatedData);

      setNotification('Idea updated successfully!');
      setTimeout(() => navigate('/admin/ideaManagment'), 2000);
    } catch (error) {
      console.error('Error updating Firestore document:', error);
      setNotification("Failed to update the idea. Please try again.");
    }
  };

  return (
    <div className="idea-submission-wrapper">
      <style jsx="true">{`
        .idea-submission-wrapper {
          max-width: 1000px;
          margin-left: 242px;
          padding: 20px;
        }

        .form-container {
          background: #fff;
          width: 100%;
          padding: 30px;
          border-radius: 10px;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        }

        .form-title {
          text-align: center;
          margin-bottom: 20px;
          font-weight: 600;
        }

        .upload-photo {
          width: 100px;
          height: 100px;
          border-radius: 50%;
          background: #e9ecef;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0 auto;
          position: relative;
          cursor: pointer;
          overflow: hidden;
        }

        .upload-photo img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .upload-photo .camera-icon {
          font-size: 30px;
          color: #6c757d;
        }

        .upload-photo .plus-icon {
          position: absolute;
          top: -8px;
          right: -8px;
          font-size: 18px;
          color: #c69e59;
        }

        .upload-photo-text {
          display: block;
          text-align: center;
          margin-top: 5px;
          color: #6c757d;
        }

        .form-label {
          font-weight: 500;
          margin-bottom: 6px;
          display: block;
          text-align: left;
        }

        .form-control,
        .form-select {
          border-radius: 8px;
        }

        .save-button {
          width: 200px;
          height: 40px;
          background: #c69e59;
          border: none;
          border-radius: 28px;
          transition: background 0.3s ease;
          margin: 20px auto 0;
          display: block;
        }

        .save-button:hover {
          background: #a88247;
        }

        @media (max-width: 768px) {
          .idea-submission-wrapper {
            padding: 20px;
            margin-left: 8px;
          }
        }
      `}</style>
      <Container>
        <Row className="justify-content-center">
          <div className="form-container">
            <h3 className="form-title">Edit Idea Submission Form</h3>
            <Form onSubmit={handleSubmit}>
              {/* Upload Image Container */}
              <div className="mb-3 text-center">
                <div className="upload-photo">
                  {formData.image ? (
                    <img src={formData.image} alt="Current Idea" />
                  ) : (
                    <FaCamera className="camera-icon" />
                  )}
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleImageChange}
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      opacity: 0,
                      cursor: 'pointer',
                    }}
                  />
                </div>
                <small className="upload-photo-text">Upload Photo</small>
              </div>
              <Row className="mb-3">
                <Col md={6}>
                  <Form.Group>
                    <Form.Label className="form-label">Title</Form.Label>
                    <Form.Control
                      type="text"
                      name="title"
                      value={formData.title}
                      onChange={handleChange}
                      placeholder="A short, catchy title for the idea."
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label className="form-label">Location</Form.Label>
                    <Form.Select
                      name="location"
                      value={formData.location}
                      onChange={handleChange}
                    >
                      <option>Global</option>
                      <option>Local</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
              <Form.Group className="mb-3">
                <Form.Label className="form-label">Description</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={2}
                  name="description"
                  value={formData.description}
                  onChange={handleChange}
                  placeholder="A quick summary of the idea (up to 250 characters)."
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label className="form-label">Long Description</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={4}
                  name="longDescription"
                  value={formData.longDescription}
                  onChange={handleChange}
                  placeholder="Full details of the idea, including goals, expected outcomes, and unique features."
                />
              </Form.Group>
              <Row>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label className="form-label">Category</Form.Label>
                    <Form.Select
                      name="category"
                      value={formData.category}
                      onChange={handleChange}
                    >
                      <option value="" disabled>
                        Select a category
                      </option>
                      {categories.map((category) => (
                        <option key={category.id} value={category.id}>
                          {category.name}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label className="form-label">Niche</Form.Label>
                    <Form.Select
                      name="niche"
                      value={formData.niche}
                      onChange={handleChange}
                    >
                      <option value="" disabled>
                        Select a niche
                      </option>
                      {filteredNiches.map((niche) => (
                        <option key={niche.id} value={niche.niche}>
                          {niche.niche}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
              <Form.Group className="mb-3">
                <Form.Label className="form-label">Type</Form.Label>
                <div className="d-flex gap-3">
                  <Form.Check
                    type="radio"
                    label="Online"
                    name="type"
                    value="Online"
                    checked={formData.type === 'Online'}
                    onChange={handleChange}
                  />
                  <Form.Check
                    type="radio"
                    label="Physical"
                    name="type"
                    value="Physical"
                    checked={formData.type === 'Physical'}
                    onChange={handleChange}
                  />
                </div>
              </Form.Group>
              <Button type="submit" className="save-button">
                Save Changings
              </Button>
            </Form>
          </div>
        </Row>
      </Container>
      {notification && (
        <Notification
          text={notification}
          onClose={() => setNotification(null)}
        />
      )}
    </div>
  );
};

export default EditIdeaSubmissionForm;
