import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';

const Slider = () => {
  return (
    <>
      <style>
        {`
          .video-player {
            width: 100%;
            height: 500px; 
            object-fit: cover;
            border-radius: 20px;
          }
        `}
      </style>
      <Container className="slider-container">
        <Row className="justify-content-center">
          <Col xs={14} md={10} sm={12}>
            <Card>
              <div>
                <video
                  className="video-player"
                  src="/video/sliderVideo.mp4"
                  autoPlay
                  loop
                  muted
                ></video>
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Slider;
