import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage"; 

const firebaseConfig = {
  apiKey: "AIzaSyBTXg1Dx5a-1dutLxdXqEhFj7x4M4A6zh0",
  authDomain: "tl-enterprise-institue2.firebaseapp.com",
  projectId: "tl-enterprise-institue2",
  storageBucket: "tl-enterprise-institue2.firebasestorage.app",
  messagingSenderId: "777573486961",
  appId: "1:777573486961:web:f93bd0cb766cb95b2526d0",
  measurementId: "G-8JRCNLM8MJ"
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);

export default app;
