import React, { useState } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

const Questions = () => {
    const [openIndex, setOpenIndex] = useState(null);

    const toggleQuestion = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    const questions = [
        { id: 1, title: 'Ready-to-Use Business Concepts', content: 'We develop and deliver complete business models, including products or services, marketing strategies, and revenue models.Everything is carefully prepared so you can get started right away.' },
        { id: 2, title: 'Immediate Implementation', content: 'Our packages include all the necessary tools and systems, such as branding, operational processes, and legal documents. You ll receive training materials and guides to ensure smooth business operations.' },
        { id: 3, title: 'Mentorship for Growth', content: 'In addition to providing ready-made business solutions, we also oﬀer mentorship. This means we guide you through the process of launching and scaling your business. - This mentorship is provided in exchange for a negotiable percentage, allowing us to actively contribute to your success.' },
        { id: 4, title: 'Flexible and Scalable', content: 'Our models are designed to be easily customized and scaled to diﬀerent markets or regions. - You retain the freedom to personalize the concept to fit your specific needs.' },
    ];

    return (
        <Container fluid>
            <Container>
                <Row className="mb-4">
                    <Col>
                        <h1 className="fw-bold text-center">How Does It Work?</h1>
                        <p className="text-center">Plug-and-Play Business Model - TL Enterprise Institute</p>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        {questions.map((question, index) => (
                            <Card  key={question.id} className="mb-3">
                                <Card.Body style={{ backgroundColor: '#FFFBEF'}} className="d-flex">
                                    <div>
                                        <span
                                            className="fw-bold me-2"
                                            style={{
                                                display: 'inline-block',
                                                height: '30px',
                                                width: '30px',
                                                borderRadius: '50%',
                                                backgroundColor: '#EDD086',
                                                textAlign: 'center',
                                                lineHeight: '30px',
                                                color: 'white',
                                            }}
                                        >
                                            {index + 1}
                                        </span>
                                        {question.title}
                                    </div>
                                    <div onClick={() => toggleQuestion(index)} style={{ cursor: 'pointer' }} className="ms-auto">
                                        {openIndex === index ? <FaChevronUp /> : <FaChevronDown />}
                                    </div>
                                </Card.Body>
                                {openIndex === index && (
                                    <Card.Body style={{ textAlign: 'left' }} >
                                        <p className="mb-0">{question.content}</p>
                                    </Card.Body>
                                )}
                            </Card>
                        ))}
                    </Col>
                    <Col md={6} className="d-flex justify-content-center align-items-start">
                        <img
                            src="/images/questions.png"
                            alt="How Does It Work"
                            width='100%'
                            height='80%'
                            className="rounded"
                        />
                    </Col>
                </Row>
            </Container>
        </Container>
    );
};

export default Questions;
