import React, { useState, useEffect } from "react";
import { Table, Button, Form, Modal, Spinner } from "react-bootstrap";
import { FaTrashAlt } from "react-icons/fa";
import { collection, addDoc, onSnapshot, deleteDoc, doc } from "firebase/firestore";
import { db } from "../../firebaseConfig";
import { fetchCategories } from "../../controllers/categoriesController"; // Adjust path
import  Notification  from "../pop_up/notification";


const NicheSettings = () => {
  const [showFilterPopup, setShowFilterPopup] = useState(false);
  const [niches, setNiches] = useState([]);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [newNiche, setNewNiche] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [categoryError, setCategoryError] = useState(""); // Validation error for category
  const [nicheError, setNicheError] = useState(""); // Validation error for niche
  const [notification, setNotification] = useState(null); 

  const handleShowFilterPopup = () => setShowFilterPopup(true);
  const handleCloseFilterPopup = () => {
    setNewNiche("");
    setSelectedCategory("");
    setCategoryError(""); 
    setNicheError(""); 
    setShowFilterPopup(false);
  };

  const handleAddNiche = async () => {
    let hasError = false;

    if (!newNiche.trim()) {
      setNicheError("Niche is required."); // Show error if niche is empty
      hasError = true;
    } else {
      setNicheError(""); // Clear error for valid niche
    }

    if (!selectedCategory) {
      setCategoryError("Please select a category."); // Show error if no category selected
      hasError = true;
    } else {
      setCategoryError(""); // Clear error for valid category
    }

    if (hasError) return;

    try {
      const selectedCategoryDoc = categories.find(
        (category) => category.name === selectedCategory
      );
      const categoryDocId = selectedCategoryDoc?.id;

      if (!categoryDocId) {
        throw new Error("Invalid category selected.");
      }

      await addDoc(collection(db, "niches"), {
        niche: newNiche,
        category: selectedCategory,
        categoryId: categoryDocId,
        timestamp: new Date(), 
      });
      

      setNotification("Niche added successfully!"); 
      handleCloseFilterPopup();
    } catch (error) {
      console.error("Error adding niche: ", error);
      setNotification("Niche ",error);
    }
  };

  const handleDeleteNiche = async (id) => {
    try {
      await deleteDoc(doc(db, "niches", id));
      setNotification("Niche deleted successfully!"); // Show success notification
    } catch (error) {
      console.error("Error deleting niche: ", error);
      setNotification("Niche ",error);
    }
  };

  useEffect(() => {
    const fetchAndSetCategories = async () => {
      try {
        const fetchedCategories = await fetchCategories();
        setCategories(fetchedCategories);
      } catch (error) {
        console.error("Error fetching categories: ", error);
        setNotification("Niche ", error); 
      }
    };

    fetchAndSetCategories();
  }, []);

  useEffect(() => {
    const unsubscribe = onSnapshot(
      collection(db, "niches"),
      (snapshot) => {
        const fetchedNiches = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        // Sort niches by timestamp in descending order
        const sortedNiches = fetchedNiches.sort(
          (a, b) => b.timestamp?.toDate() - a.timestamp?.toDate()
        );
        setNiches(sortedNiches);
        setLoading(false);
      },
      (error) => {
        console.error("Error fetching niches: ", error);
        setNotification("Niche ", error); 
        setLoading(false);
      }
    );
  
    return () => unsubscribe();
  }, []);
  

  return (
    <>
      <style type="text/css">
        {`
          .category-settings-container {
            max-width: 800px;
            margin: 0 auto;
            padding: 20px;
            padding-left: 220;
            margin-top: 30px;
            background: #fff;
            border-radius: 10px;
            box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
          }
          .settings-title {
            font-size: 40px;
            font-weight: bold;
            margin-bottom: 30px;
            color: #000;
          }
          .category-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 20px;
          }
          .category-title {
            font-size: 24px;
            font-weight: 700;
            color: #000;
          }
          .add-category-btn {
            background-color: #d6a63e;
            color: #fff;
            border: none;
            border-radius: 30px;
          }
          .category-table {
            width: 100%;
          }
          .category-table th {
            font-weight: 600;
            color: #000;
          }
          .delete-btn {
            background: none;
            border: none;
            color: #555;
          }
          @media (max-width: 800px) {
            .category-settings-container {
              padding-left: 0;
              padding-top: 20px;
            }
          }
        `}
      </style>

      <div className="category-settings-container">
        <div className="category-header">
          <h2 className="category-title">Niche & Category</h2>
          <Button className="add-category-btn" onClick={handleShowFilterPopup}>
            Add Niche
          </Button>
        </div>

        {loading ? (
          <div className="loading-text">
            <Spinner animation="border" /> Loading...
          </div>
        ) : (
          <Table bordered hover className="category-table">
            <thead>
              <tr>
                <th>
                  <Form.Check type="checkbox" />
                </th>
                <th>Niche Name</th>
                <th>Category Name</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {niches.map((niche) => (
                <tr key={niche.id}>
                  <td>
                    <Form.Check type="checkbox" />
                  </td>
                  <td>{niche.niche}</td>
                  <td>{niche.category}</td>
                  <td>
                    <FaTrashAlt
                      className="icon-trash-alt"
                      onClick={() => handleDeleteNiche(niche.id)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </div>

      <Modal show={showFilterPopup} onHide={handleCloseFilterPopup} centered>
        <Modal.Body>
          <div className="filter-popup">
            <h4>Niche & Category</h4>
            <Form>
              <Form.Group className="mb-3">
                <Form.Control
                  type="text"
                  placeholder="Niche"
                  className="form-control"
                  value={newNiche}
                  onChange={(e) => {
                    setNewNiche(e.target.value);
                    setNicheError(""); // Clear error on valid input
                  }}
                />
                {nicheError && (
                  <div style={{ color: "red", marginTop: "5px", fontSize: "14px" }}>
                    {nicheError}
                  </div>
                )}

                <Form.Select
                  className="mt-4 form-control"
                  value={selectedCategory}
                  onChange={(e) => {
                    setSelectedCategory(e.target.value);
                    setCategoryError(""); // Clear error on valid selection
                  }}
                >
                  <option value="">Select Category</option>
                  {categories.map((category) => (
                    <option key={category.id} value={category.name}>
                      {category.name}
                    </option>
                  ))}
                </Form.Select>
                {categoryError && (
                  <div style={{ color: "red", marginTop: "5px", fontSize: "14px" }}>
                    {categoryError}
                  </div>
                )}
              </Form.Group>
              <Button className="save-button" onClick={handleAddNiche}>
                Save
              </Button>
            </Form>
          </div>
        </Modal.Body>
      </Modal>

      {/* Render Notification */}
      {notification && (
        <Notification
          text={notification}
          onClose={() => setNotification(null)}
        />
      )}
    </>
  );
};

export default NicheSettings;
